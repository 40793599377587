@import "../Theme.scss";

@keyframes double-pulse-white {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5), 0 0 0 0 rgba(255, 255, 255, 0.5);
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5), 0 0 0 0 rgba(255, 255, 255, 0.5);
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5), 0 0 0 0 rgba(255, 255, 255, 0.5);
        -khtml-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5), 0 0 0 0 rgba(255, 255, 255, 0.5);
    }
    33% {
        box-shadow: 0 0 0 25px rgba(255, 255, 255, 0), 0 0 0 0 rgba(255, 255, 255, 0.5);
        -moz-box-shadow: 0 0 0 25px rgba(255, 255, 255, 0), 0 0 0 0 rgba(255, 255, 255, 0.5);
        -webkit-box-shadow: 0 0 0 25px rgba(255, 255, 255, 0), 0 0 0 0 rgba(255, 255, 255, 0.5);
        -khtml-box-shadow: 0 0 0 25px rgba(255, 255, 255, 0), 0 0 0 0 rgba(255, 255, 255, 0.5);
    }
    66% {
        box-shadow: 0 0 5px 25px rgba(255, 255, 255, 0), 0 0 0 15px rgba(255, 255, 255, 0);
        -moz-box-shadow: 0 0 5px 25px rgba(255, 255, 255, 0), 0 0 0 15px rgba(255, 255, 255, 0);
        -webkit-box-shadow: 0 0 5px 25px rgba(255, 255, 255, 0), 0 0 0 15px rgba(255, 255, 255, 0);
        -khtml-box-shadow: 0 0 5px 25px rgba(255, 255, 255, 0), 0 0 0 15px rgba(255, 255, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0), 0 0 5px 15px rgba(255, 255, 255, 0);
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0), 0 0 5px 15px rgba(255, 255, 255, 0);
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0), 0 0 5px 15px rgba(255, 255, 255, 0);
        -khtml-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0), 0 0 5px 15px rgba(255, 255, 255, 0);
    }
}

@keyframes double-pulse-primary {
    0% {
        box-shadow: 0 0 0 0 rgba(72, 87, 138, 0.5), 0 0 0 0 rgba(72, 87, 138, 0.5);
        -moz-box-shadow: 0 0 0 0 rgba(72, 87, 138, 0.5), 0 0 0 0 rgba(72, 87, 138, 0.5);
        -webkit-box-shadow: 0 0 0 0 rgba(72, 87, 138, 0.5), 0 0 0 0 rgba(72, 87, 138, 0.5);
        -khtml-box-shadow: 0 0 0 0 rgba(72, 87, 138, 0.5), 0 0 0 0 rgba(72, 87, 138, 0.5);
    }
    35% {
        box-shadow: 0 0 0 20px rgba(72, 87, 138, 0), 0 0 0 0 rgba(72, 87, 138, 0.5);
        -moz-box-shadow: 0 0 0 20px rgba(72, 87, 138, 0), 0 0 0 0 rgba(72, 87, 138, 0.5);
        -webkit-box-shadow: 0 0 0 20px rgba(72, 87, 138, 0), 0 0 0 0 rgba(72, 87, 138, 0.5);
        -khtml-box-shadow: 0 0 0 20px rgba(72, 87, 138, 0), 0 0 0 0 rgba(72, 87, 138, 0.5);
    }
    75% {
        box-shadow: 0 0 5px 20px rgba(72, 87, 138, 0), 0 0 0 15px rgba(72, 87, 138, 0);
        -moz-box-shadow: 0 0 5px 20px rgba(72, 87, 138, 0), 0 0 0 15px rgba(72, 87, 138, 0);
        -webkit-box-shadow: 0 0 5px 20px rgba(72, 87, 138, 0), 0 0 0 15px rgba(72, 87, 138, 0);
        -khtml-box-shadow: 0 0 5px 20px rgba(72, 87, 138, 0), 0 0 0 15px rgba(72, 87, 138, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(72, 87, 138, 0), 0 0 5px 15px rgba(72, 87, 138, 0);
        -moz-box-shadow: 0 0 0 0 rgba(72, 87, 138, 0), 0 0 5px 15px rgba(72, 87, 138, 0);
        -webkit-box-shadow: 0 0 0 0 rgba(72, 87, 138, 0), 0 0 5px 15px rgba(72, 87, 138, 0);
        -khtml-box-shadow: 0 0 0 0 rgba(72, 87, 138, 0), 0 0 5px 15px rgba(72, 87, 138, 0);
    }
}

@keyframes double-pulse-primary-small {
    0% {
        box-shadow: 0 0 0 0 rgba(72, 87, 138, 1), 0 0 0 0 rgba(72, 87, 138, 1);
        // -moz-box-shadow: 0 0 0 0 rgba(72, 87, 138, 0.5), 0 0 0 0 rgba(72, 87, 138, 0.5);
        // -webkit-box-shadow: 0 0 0 0 rgba(72, 87, 138, 0.5), 0 0 0 0 rgba(72, 87, 138, 0.5);
        // -khtml-box-shadow: 0 0 0 0 rgba(72, 87, 138, 0.5), 0 0 0 0 rgba(72, 87, 138, 0.5);
    }
    35% {
        box-shadow: 0 0 0 8px rgba(72, 87, 138, 0), 0 0 0 0 rgba(72, 87, 138, 1);
        // -moz-box-shadow: 0 0 0 10px rgba(72, 87, 138, 0), 0 0 0 0 rgba(72, 87, 138, 0.5);
        // -webkit-box-shadow: 0 0 0 10px rgba(72, 87, 138, 0), 0 0 0 0 rgba(72, 87, 138, 0.5);
        // -khtml-box-shadow: 0 0 0 10px rgba(72, 87, 138, 0), 0 0 0 0 rgba(72, 87, 138, 0.5);
    }
    75% {
        box-shadow: 0 0 5px 8px rgba(72, 87, 138, 0), 0 0 0 13px rgba(72, 87, 138, 0);
        // -moz-box-shadow: 0 0 5px 10px rgba(72, 87, 138, 0), 0 0 0 15px rgba(72, 87, 138, 0);
        // -webkit-box-shadow: 0 0 5px 10px rgba(72, 87, 138, 0), 0 0 0 15px rgba(72, 87, 138, 0);
        // -khtml-box-shadow: 0 0 5px 10px rgba(72, 87, 138, 0), 0 0 0 15px rgba(72, 87, 138, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(72, 87, 138, 0), 0 0 5px 13px rgba(72, 87, 138, 0);
        // -moz-box-shadow: 0 0 0 0 rgba(72, 87, 138, 0), 0 0 5px 15px rgba(72, 87, 138, 0);
        // -webkit-box-shadow: 0 0 0 0 rgba(72, 87, 138, 0), 0 0 5px 15px rgba(72, 87, 138, 0);
        // -khtml-box-shadow: 0 0 0 0 rgba(72, 87, 138, 0), 0 0 5px 15px rgba(72, 87, 138, 0);
    }
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 1px lighten($primary-color, 20%);
    }
    70% {
        box-shadow: 0 0 0 4px lighten($primary-color, 40%);
    }
    100% {
        box-shadow: 0 0 0 1px lighten($primary-color, 20%);
    }
}

@keyframes pulse-size {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes minor-bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(-4px);
    }
    60% {
        transform: translateY(-1px);
    }
}

.contained-button-pulse {
    border-radius: shape(borderRadius);
    -webkit-border-radius: shape(borderRadius);
    -moz-border-radius: shape(borderRadius);
    -khtml-border-radius: shape(borderRadius);
    animation: double-pulse-primary 2.5s linear infinite;
}
