.effort-log-table {
    .header-cell {
        background-color: palette(background, default);

        font-size: 0.95rem;
        font-weight: 600;
        line-height: 1.75;
        letter-spacing: 0.02857em;
        text-align: right;
        padding: 0;

        &.background {
            background-color: palette(background, paper) !important;
        }

        &.activity {
            background-color: palette(accent, greenBackground);
            color: palette(accent, greenText);
        }
        
        &.cost {
            background-color: palette(accent, yellowBackground);
            color: palette(accent, yellowText);
        }

        &.productivity {
            background-color: palette(accent, blueBackground);
            color: palette(accent, blueText);
        }

        &.effort {
            background-color: palette(accent, purpleBackground);
            color: palette(accent, purpleText);
        }

        &.sticky {
            position: -webkit-sticky !important;
            position: sticky !important;
            left: 0 !important;
            z-index: 3;
        }

        .header-button {
            span {
                justify-content: left;
                padding: 0px 10px 0px 10px;
            }
            span span:nth-child(1) {
                padding: 0;
            }
            padding: 0;
            width: 100%;
            height: 100%;
        }
    }

    .header-cell[data-left="true"] {
        @extend .header-cell;
        border-radius: 3px 0 0 0;
        padding: 0px 10px 0px 10px;
    }

    .header-cell[data-Highlight="true"] {
        @extend .header-cell;
        background-color: palette(primary, main);
        color: palette(primary, contrastText);
        -webkit-transition: background 0.25s ease-in-out;
        -moz-transition: background 0.25s ease-in-out;
        -o-transition: background 0.25s ease-in-out;
        -ms-transition: background 0.25s ease-in-out;
        transition: background 0.25s ease-in-out;
        button {
            color: palette(primary, contrastText);
        }
    }

    .data-cell {
        border-style: solid;
        border-color: palette(background, default);
        border-width: 0.075rem;
    
        white-space: nowrap;

        text-align: right;

        &.unused {
            background-color: palette(background, default) !important;
            background-image: repeating-linear-gradient(
                -45deg,
                palette(background, paper),
                palette(background, paper) 5%,
                palette(background, default) 5%,
                palette(background, default) 10%
            ) !important;
        }

        &.button {
            padding: 0;

            button {
                padding: 0;
                width: 100%;
                height: 100%;
                justify-content: flex-end;
                
                span {
                    padding-right: 5px;
                    span {
                        @include typography(body2);
                        padding-right: 0;
                    }
                }
            }
            button:hover {
                box-shadow: 0px 0px 3px 1px palette(primary, main);
                -webkit-transition: all 0.25s ease;
                -moz-transition: all 0.25s ease;
                -o-transition: all 0.25s ease;
                -ms-transition: all 0.25s ease;
                transition: all 0.25s ease;
            }
        }
    }
    
    .data-cell[data-disabled="true"] {
        @extend .data-cell;
        color: rgba(0, 0, 0, 0.26);
    }

    .data-cell[data-border-top="true"] {
        border-top-style: solid;
        border-top-color: palette(background, default);
        border-top-width: 0.15rem;
    }

    .background-cell {
        background-color: palette(background, paper);
    }
        
    .row-separator {
        background-color: palette(background, default);
        height: 6px;
    }
}

.effort-log-table-header {
    padding: 0px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-width {
    width: 100% !important;
}
@media (min-width: breakpoints(md)) {
    .page-width {
        width: calc(100vw - 115px) !important;
        &.constrain-expanded {
            width: calc(100vw - 285px) !important;
        }
    }
}

.effort-log-form {
    margin: auto !important;
    width: 100% !important;
    background-color: palette(grey, 50) !important;

    &.sm {
        max-width: breakpoints(sm) !important;
    }
    &.md {
        max-width: breakpoints(md) !important;
    }
    &.lg {
        max-width: breakpoints(lg) !important;
    }

    .form-body {
        max-height: calc(100vh - 240px) !important;
        overflow-y: auto !important;
    }

    .MuiCardHeader-root {
        font-size: 10px;
        padding: 10px 1em;
    }
    .MuiCardHeader-title {
        font-size: 2em !important;
        font-weight: 500 !important;
    }
    .MuiCardHeader-action {
        padding-top: 6px;
        padding-right: 6px;
        .MuiButtonBase-root {
            padding: 5px;
        }
    }
    .MuiCardContent-root {
        border-top: #e2e2e2 solid 1px;
        border-bottom: #e2e2e2 solid 1px;
    }
    .MuiCardActions-root {
        padding: 10px 1em;
    }
    .MuiChip-root {
        height: 25px !important;
        border-radius: 25px;
    }

    &.short {
        .MuiCardContent-root {
            max-height: calc(100vh - 250px) !important;
        }
    }
}