.header {
    position: sticky;
    top: 0;
    z-index: 1190;
    transition: box-shadow 0.2s ease-in-out;
    &.simulator {
        box-shadow: shadows(4);
    }
    .logo {
        font-size: 22px;
        font-weight: 600 !important;
        letter-spacing: 0.2em !important;
        line-height: 0em !important;
        text-decoration: none;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        img {
            width: 30px !important;
            margin: auto;
            margin-top: -5px;
            margin-right: 5px;
        }
    }
    .tab {
        @media (min-width: breakpoints(md)) {
            padding-left: 10px !important;
            padding-right: 10px !important;
            margin-left: 15px !important;
        }
        font-weight: 600 !important;
        margin-left: 5px !important;
        margin-bottom: -7.5px !important;
        border-radius: shape(borderRadius) shape(borderRadius) 0px 0px !important;
        color: palette(primary, contrastText) !important;
        &.active {
            background-color: #fff !important;
            color: palette(primary, main) !important;
            &.basic {
                background-color: transparent !important;
                color: white !important;
            }
        }
    }
    .top-right {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        align-items: center;
        #account-button {
            min-width: 24px !important;
            height: 35px;
            background-color: #fff;
            margin-left: 10px;
            box-shadow: shadows(4);
            .user-name {
                @include typography(body2);
                padding-left: 10px;
                padding-right: 5px;
                line-height: 1 !important;
            }
            @media (max-width: breakpoints(lg)) {
                background-color: transparent !important;
                box-shadow: none !important;
                color: white !important;
                border-radius: 50px;
                padding: 0px;
                .user-name {
                    display: none;
                }
                svg {
                    font-size: 2.2em !important;
                }
            }
        }
        #simulator-button {
            border-radius: 50px;
            background-color: #fff;
            margin-left: 10px;
            height: 28px;
            padding: 0px 15px;
            box-shadow: shadows(4);
            .MuiButton-label {
                padding-top: 2px;
            }
        }
    }
}
.header-drawer {
    .MuiBackdrop-root {
        top: 45px !important;
    }
    .MuiDrawer-paperAnchorTop {
        top: 45px !important;
    }
}
.header-menu {
    .item {
        padding: 5px;
        &.sub {
            margin-left: 25px;
        }
    }
    .app-access {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 10px 50px 10px 50px;
    }
}
