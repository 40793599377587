.section-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 50px;
    .text {
        @include typography(body1);
        font-weight: 600 !important;
    }
    .MuiButton-label {
        font-weight: 800;
        color: palette(secondary, main);
    }
}

.contract-overview {
    padding-top: 10px !important;

    .MuiCard-root {
        padding: 15px;
        width: 100%;
        background-color: palette(grey, 50);
    }

    .info {
        .MuiTypography-root {
            @include typography(h6);
            font-weight: 600 !important;
            padding-bottom: 10px !important;
        }
        .MuiTableCell-head {
            height: 32px !important;
            font-weight: 600;
            color: palette(grey, A600);
        }
        .MuiButton-root {
            height: 25px !important;
        }
        .costs-table {
            margin-top: 10px;
            .MuiTable-root {
                border-radius: shape(borderRadius) !important;
                background-color: #fff;
                box-shadow: 0px 0px 0px 2px palette(grey, 200) inset !important;
            }
            .MuiTableRow-root {
                outline: 1px solid palette(grey, 200) !important;
                .MuiTableCell-head {
                    color: palette(grey, A600);
                }
                .MuiTableCell-head:first-child {
                    border-right: 2px solid palette(grey, 200) !important;
                    font-weight: 700;
                }
            }
            .MuiTableCell-head {
                height: 35px !important;
            }
        }
    }

    .details {
        .MuiTable-root {
            border-radius: shape(borderRadius) !important;
            background-color: #fff;
            box-shadow: 0px 0px 0px 2px palette(grey, 200) inset !important;
        }
        .MuiTableRow-root {
            outline: 1px solid palette(grey, 200) !important;
            .MuiTableCell-head {
                color: palette(grey, A600);
            }
            .MuiTableCell-head:first-child {
                border-right: 2px solid palette(grey, 200) !important;
                font-weight: 700;
            }
        }
        .MuiTableCell-head {
            height: 40px !important;
        }
    }

    .patient {
        .heading {
            color: palette(grey, 50) !important;
            box-shadow: 0px 0px 0px 1.5px palette(primary, dark) inset;
            background-color: palette(primary, main);
            border-top-left-radius: shape(borderRadius);
            border-top-right-radius: shape(borderRadius);
            font-size: 16px;
        }
        .MuiTable-root {
            border-radius: shape(borderRadius) !important;
            background-color: #fff;
            box-shadow: 0px 0px 0px 2px palette(grey, 200) inset !important;
        }
        .MuiTableRow-root {
            outline: 1px solid palette(grey, 200) !important;
            .MuiTableCell-head {
                color: palette(grey, A600);
            }
            .MuiTableCell-head:first-child {
                font-weight: 700;
            }
        }
        .MuiTableCell-head {
            height: 40px !important;
        }
    }
}

.warning-text {
    .MuiTypography-root {
        color: palette(primary, dark);
        padding: 3px 1px 0px 2px;
        font-weight: 700;
        &.title {
            font-weight: 700;
            padding-top: 15px !important;
            padding-bottom: 10px !important;
            &.emphasis {
                font-style: italic;
                font-size: 18px;
            }
        }
        &.middle {
            padding-bottom: 10px !important;
        }
        &.last {
            padding-bottom: 20px !important;
        }
        &.error {
            color: palette(error, dark);
            font-style: italic;
        }
    }
    ul {
        padding-inline-start: 30px;
        li {
            list-style-position: outside;
            list-style-type: disc;
            margin: 0px;
            padding: 0px;
            padding-bottom: 20px;
        }
        li:not(:last-child) {
            padding-bottom: 5px;
        }
    }
    .issue-path {
        text-decoration: underline;
        margin-block-start: 0px;
        margin-block-end: 0px;
        color: palette(secondary, main);
        font-weight: 600;
        cursor: pointer;
    }
}
