Button .icon {
    margin: 0px 7px 0px -4px;
    padding: 1px;
    &.small {
        margin: 0px;
        padding: 3px;
    }
}

.animated-button {
    @media (min-width: breakpoints(sm)) {
        color: palette(primary, contrastText) !important;
    }
    color: palette(primary, dark) !important;
    margin: 0px 5px !important;
    border-radius: 3px !important;
    height: 35px;

    transform: scale(1);
    transition: all 0.3s ease-out !important;

    &:hover {
        transform: scale(0.95);
    }

    &.outline {
        box-shadow: inset 0px 0px 0px 2px palette(primary, contrastText);
        margin: 0px 8px !important;
        min-width: 80px;
        will-change: transform, filter;
        transform-style: preserve-3d;

        &:before {
            content: "";
            box-sizing: border-box;
            height: 20px;
            position: absolute;
            top: 15px;
            left: 10px;
            right: 10px;
            z-index: -1;
        }
    }

    &.small {
        font-size: small;
    }
}

.filter-button {
    border: 1px solid palette(grey, 400) !important;
    height: 30.5px !important;
    line-height: 0px !important;
    background-color: white !important;
    color: palette(grey, 700) !important;
    font-weight: 600 !important;
    padding: 3px 10px !important;
    .MuiButton-startIcon {
        margin-left: -2px !important;
        margin-right: 4px !important;
    }
    &.select {
        padding: 3px 8px !important;
        font-weight: 400 !important;
        .MuiSvgIcon-root {
            font-size: 25px;
        }
        .MuiButton-endIcon {
            margin-left: 0px !important;
            margin-right: -6px !important;
        }
        .MuiButton-label {
            padding-top: 3px;
        }
    }
    .MuiButton-iconSizeMedium {
        margin-top: -3px !important;
    }
    .MuiButton-label {
        padding-top: 1px;
    }
}

.add-division-button {
    padding: 2px 8px !important;
}

.delete-button {
    background-color: palette(error, main) !important;
    color: white !important;
}

.chips {
    display: flex;
    flex-wrap: wrap;
    .chip {
        margin: 2px;
    }
}

.outlined-button {
    height: 32px;
    border: 1px solid palette(primary, main) !important;
    color: palette(primary, main) !important;
    &.grey {
        border: 1px solid palette(grey, 500) !important;
        color: palette(grey, 800) !important;
    }
}

.rounded-chip {
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 25px;
    padding: 4px 20px;
    text-align: center;
}
.grey-chip {
    @extend .rounded-chip;
    background-color: palette(grey, 300);
    color: palette(grey, 600);
}
.red-chip {
    @extend .rounded-chip;
    background-color: palette(accent, redBackground);
    color: palette(accent, redText);
}
.green-chip {
    @extend .rounded-chip;
    background-color: palette(accent, greenBackground);
    color: palette(accent, greenText);
}
.blue-chip {
    @extend .rounded-chip;
    background-color: palette(accent, blueBackground);
    color: palette(accent, blueText);
}
.yellow-chip {
    @extend .rounded-chip;
    background-color: palette(accent, yellowBackground);
    color: palette(accent, yellowText);
}

.button-progress {
    color: palette(primary, light);
    position: "absolute";
    top: "50%";
    left: "50%";
    margin-top: -12;
    margin-left: -12;
}


.Mui-disabled {
    &.white-icon {
        visibility: hidden;
    }
}
.white-icon {
    .MuiIconButton-label {
        color: white;
    }
    padding: 0px !important;
    border-radius: 20px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -khtml-border-radius: 100px;
    &.cta {
        animation: double-pulse-white 3s linear infinite;
    }
}
