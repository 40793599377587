.ept-coordinator-chip {
    margin-right: 2px !important;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
    background-color: #c5e063 !important;
}
.ept-provider-chip {
    margin-right: 2px !important;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
    background-color: #ffc2b4 !important;
}
.ept-coordinator-avatar {
    background-color: #c5e063 !important;
    color: black !important;
}
.ept-provider-avatar {
    background-color: #ffc2b4 !important;
    color: black !important;
}
.ept-custom-icon {
    @include typography(icon);
    height: 14px;
}
.ept-dragged-row {
    background-color: rgba(245,245,245, 0.75);
    box-shadow: 0 0 7px 3px rgba(0,0,0,0.075);
}