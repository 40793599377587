.tree-item-icon {
    width: 25px;
    height: 25px;
    .arrow {
        font-size: 30px;
        margin: 0px;
        width: 25px;
        height: 25px;
        color: palette(primary, main);
        &.collapse {
            padding: 8px 7px;
        }
        &.expand {
            padding: 7px 8px;
        }
    }
    .chevron {
        font-size: 30px;
        margin: 0px;
        width: 25px;
        height: 25px;
        color: palette(primary, main);
    }
    &:hover {
        background-color: palette(grey, 300);
        border-radius: 50px;
    }
}

.division-tree {
    width: 100%;
}

.division-tree-item {
    .MuiTreeItem-content {
        padding: 0px 0px;
        color: palette(grey, 700);
        border-bottom: 1px solid #dddddd;
    }
    .MuiTreeItem-label {
        padding-top: 5px;
    }
    .MuiTreeItem-iconContainer {
        margin-right: 5px;
    }
    &.sparse {
        .MuiTreeItem-label {
            padding-top: 10px;
            padding-bottom: 5px;
        }
    }
}
