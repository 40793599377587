.contract-card {
    cursor: pointer;
    background-color: #fff;
    box-shadow: shadows(1);
    transform: scale(1);
    transition: 0.2s !important;
    border: 2px solid palette(grey, 200) !important;
    @media (min-width: breakpoints(sm)) {
        height: 175px !important;
    }
    &:hover {
        box-shadow: shadows(3);
        transform: translate(0, -3px);
    }
    .name {
        @include typography(subtitle2);
        font-weight: 600 !important;
        margin: auto;
        margin-left: 20px;
        padding: 10px 0px;
    }
    .info {
        color: palette(grey, 400) !important;
        position: absolute;
        top: 10px;
        right: 0;
    }
    .summary {
        height: 55%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .info-row {
            margin-left: 20px;
            margin-right: 20px;
            display: flex;
            justify-content: space-between;
            @media (max-width: breakpoints(sm)) {
                padding-top: 10px !important;
                padding-bottom: 10px !important;
            }
            div:first-of-type {
                font-weight: 600 !important;
                color: palette(grey, 600);
            }
        }
    }
    .content {
        @media (max-width: breakpoints(sm)) {
            padding-top: 30px !important;
            padding-bottom: 30px !important;
        }
    }
    overflow: visible !important;
}
.divider {
    border: 1px solid palette(grey, 200) !important;
    width: 100%;
}
.tip-padding {
    padding: 10px !important;
}
.signed-tag {
    position: absolute;
    top: -7.5px;
    right: -5px;
    font-weight: 600;
    color: white;
    background-color: palette(secondary, main);
    border-radius: 25px;
    padding: 0px 15px;
}

.filter-menu {
    .MuiList-root {
        border: 1px solid palette(grey, 300);
        box-shadow: shadows(4);
        padding: 20px 0px;
    }
    .MuiListItem-root {
        padding: 8px 20px;
    }
    .section-title {
        @include typography(subtitle2);
        font-weight: 600 !important;
        text-transform: uppercase;
        color: palette(grey, A700) !important;
        line-height: 0px !important;
        .MuiSvgIcon-root {
            font-size: medium !important;
            color: palette(grey, 500);
            margin-left: 10px;
        }
    }
    .form {
        padding: 0px 20px !important;
    }
    .spacer {
        display: flex;
        border-bottom: 1px solid #ddd !important;
        margin: 20px 0px !important;
    }
    .sub-section-title {
        @include typography(body1);
        font-weight: 600;

        .MuiSvgIcon-root {
            font-size: medium !important;
            color: palette(grey, 500);
            margin-left: 5px;
        }
    }
}
