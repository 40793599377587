.status-chip {
    display: inline-block;
    margin: 2.5px;
    padding: 4px 15px 0px 15px;
    border-radius: shape(borderRadius);
    text-align: center;
    .MuiTypography-root {
        @include typography(body2);
        font-weight: 600 !important;
    }
    &.fixed-width-small {
        width: 110px;
    }
    &.fixed-width-large {
        width: 150px;
    }
    &.bottom-margin {
        @extend .status-chip;
        margin-bottom: 5px;
    }
    &.NOT_INVOICED {
        border: black;
        border-style: solid;
        border-width: 1px;
        color: black;
    }
    &.NOT_SENT {
        background-color: palette(accent, grayBackground);
        color: palette(accent, grayText);
    }
    &.NOT_PAID {
        background-color: palette(accent, redBackground);
        color: palette(accent, redText);
    }
    &.PARTIAL {
        background-color: palette(accent, yellowBackground);
        color: palette(accent, yellowText);
    }
    &.CURRENT {
        background-color: palette(accent, greenBackground);
        color: palette(accent, greenText);
    }
    &.PAID {
        background-color: palette(accent, blueBackground);
        color: palette(accent, blueText);
    }
}

.invoices-summary-card {
    width: 100%;
    .card-content {
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    .status-div {
        @media (max-width: breakpoints(sm)) {
            width: 100%;
        }
        .title {
            @include typography(h6);
            font-size: 1.05rem !important;
        }
    }
    .totals-div {
        padding-top: 10px;
        @media (max-width: breakpoints(md)) {
            width: 100%;
            margin-top: 20px;
        }
        .total-row {
            display: flex;
            justify-content: space-between;
            min-width: 250px;

            .label {
                @include typography(body2);
                color: palette(grey, 600);
            }
            .value {
                @include typography(body2);
                font-weight: 700 !important;
            }
        }
    }
}