.usv-table-header {
    background-color: palette(secondary, main) !important;
    @include typography(body1);
    .primary-cell {
        @include typography(body1);
        background-color: palette(grey, 300) !important;
        border: 1px solid palette(grey, 400) !important;
    }
    .usv-header-cell-label {
        color: #fff !important;
    }
    .max-width-patient {
        color: #fff !important;
        width: 180px !important;
        min-width: 180px !important;
    }
    .max-width-date {
        color: #fff !important;
        width: 100px !important;
    }
    .max-width-actions {
        color: #fff !important;
        width: 60px !important;
    }
    .max-width-money {
        color: #fff !important;
        width: 120px !important;
    }
    .max-width-users {
        color: #fff !important;
        min-width: 160px !important;
    }
    .max-width-description {
        color: #fff !important;
        width: 300px !important;
        min-width: 300px !important;
    }
    .max-width-pp {
        color: #fff !important;
        min-width: 300px !important;
    }
}

.sv-tab {
    border-bottom: 1px solid #b4b5c5 !important;
    &.spacing {
        margin-top: 5px;
        margin-bottom: 15px;
    }
}
.sv-body-table-label {
    background-color: palette(primary, main) !important;
    @include typography(body1);
    border-bottom: 1.5px solid palette(primary, dark) !important;
    color: #fff !important;
}
.sv-body-table-label-stick {
    background-color: palette(primary, main) !important;
    @include typography(body1);
    color: #fff !important;
    border-bottom: 1.5px solid palette(primary, dark) !important;
    left: 0px !important;
    position: sticky !important;
    div {
        display: flex !important;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
    }
}
.sv-table-header {
    .sv-primary-cell {
        @extend .soe-grid-top-sticky;
        @include typography(body1);
        background-color: palette(grey, 300) !important;
        border: 1px solid palette(grey, 400);
    }
    .sv-header-cell-label {
        @extend .soe-grid-top-sticky;
        display: flex;
        align-items: center;
    }
    .sv-table-header-starter {
        background-color: white !important;
        left: 0 !important;
        top: 0 !important;
        position: sticky !important;
        z-index: 2;
        border-right: 1px solid palette(grey, 400);
        border-bottom: 1px solid palette(grey, 400);
    }
}
.sv-table-body-section-starter {
    background-color: palette(grey, 300) !important;
    border: 1px solid palette(grey, 400);
    left: 0 !important;
    position: sticky !important;

    .sv-patient-icon {
        display: none;
        margin-right: 0.5rem;
    }

    &.canDelete:hover {
        background-color: palette(grey, 400) !important;
        cursor: pointer;

        .sv-patient-icon {
            display: block;
        }
    }
}

.sv-table-inactive {
    background-color: palette(background, default);
    background-image: repeating-linear-gradient(
        -45deg,
        palette(grey, 100),
        palette(grey, 100) 5%,
        palette(grey, 300) 5%,
        palette(grey, 300) 10%
    );
}

.patient-visit-cell:hover {
    background-color: palette(grey, 400);
    cursor: pointer;
}
.right-input-text {
    text-align: right;
}
.usv-row-hover-cursor {
    cursor: pointer;
}
.usv-coordinator-chip {
    margin-right: 10px !important;
    background-color: #c5e063 !important;
}
.usv-provider-chip {
    margin-right: 10px !important;
    background-color: #ffc2b4 !important;
}
.usv-word-break {
    word-break: break-all !important;
}
.visit-employee-header {
    .primary-cell {
        @include typography(body1);
        color: palette(grey, 800) !important;
        font-weight: 600 !important;
        background-color: palette(grey, 200) !important;
        border: 1.5px solid palette(grey, 300) !important;
    }
}
.table-accordion {
    padding: 10px;
    .MuiAccordionDetails-root {
        padding: 0px;
    }
    .pulse-btn {
        background-color: $primary-color;
        color: white;
        box-shadow: lighten($primary-color, 20%);
        animation: pulse 3s infinite;
        height: 30px;
    }
    .disabled-btn {
        background-color: transparent;
        color: palette(grey, 400);
        pointer-events: none;
    }
    .pulse-btn:hover {
        background-color: $primary-color;
    }
    .card-header {
        border-top-left-radius: shape(borderRadius);
        border-top-right-radius: shape(borderRadius);
        border: 1px solid palette(primary, dark);
        height: 40px !important;
        background-color: palette(primary, main);
        color: white;
        display: flex;
        align-items: center;
        padding-left: 6px;
        @include typography(subtitle2);
    }
}

@media (min-width: breakpoints(md)) {
    .contained-expanded-unscheduled {
        width: calc(100vw - 470px) !important;
    }
}

.instruction-padding {
    padding: 30px 5px 20px;
}

.bselect-no-margin {
    margin-top: 0px !important;
}

.patient-modal-separator {
    background-color: #e2e2e2;
    width: 100%;
    height: 1px;
    margin-bottom: 15px;
}

.patient-status-chip {
    display: inline-block;
    margin: 2.5px;
    padding: 4px 15px 0px 15px;
    border-radius: shape(borderRadius);
    text-align: center;
    width: 110px;
    &.INACTIVE {
        border: black;
        border-style: solid;
        border-width: 1px;
        color: black;
    }
}

.patient-note-table {
    max-height: 375px !important;
    overflow-y: auto !important;
    td {
        border-style: solid;
        border-color: palette(background, default);
        border-width: 0.075rem;
    }

    th {
        background-color: palette(primary, main);
        color: palette(primary, contrastText);
    }

    thead tr th:nth-child(1) {
        div {
            display: flex;
            align-items: center;
        }
        cursor: pointer;
    }

    tbody {
        tr {
            td:nth-child(2) {
                white-space: pre-line;
            }

            td:nth-child(3) {
                button {
                    padding: 6px;
                }
            }
        }
        tr[data-selected="false"] {
            td:nth-child(2):hover {
                cursor: pointer;
                background-color: palette(grey, 400);

                -webkit-transition: background-color 0.25s ease;
                -moz-transition: background-color 0.25s ease;
                -o-transition: background-color 0.25s ease;
                -ms-transition: background-color 0.25s ease;
                transition: background-color 0.25s ease;
            }
        }
    }

    tfoot {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;

        td {
            background-color: palette(background, paper);
        }
        td:nth-child(1) {
            background-color: palette(background, default);
            background-image: repeating-linear-gradient(
                -45deg,
                palette(background, paper),
                palette(background, paper) 5%,
                palette(background, default) 5%,
                palette(background, default) 10%
            );
        }
    }
}

.patient-note-tooltip {
    ul {
        list-style-position: outside;

        li {
            white-space: pre-line;
            position: relative;
            left: -0.25rem;
            padding-bottom: 0.5rem;
        }
        li:last-child {
            padding-bottom: 0 !important;
        }
    }
    .tooltip-inactive {
        font-weight: bold;
        font-size: medium;
        margin-right: 20px;
    }
}
