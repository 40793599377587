.addendum-history {
    .aligned-row {
        padding: 10px 0px;
        .text {
            @include typography(subtitle1);
            font-weight: 600 !important;
        }
        .MuiSvgIcon-root {
            color: palette(grey, 500);
            font-size: large;
            margin: 0px 10px;
        }
        .create-addendum {
            .MuiSvgIcon-root {
                color: palette(primary, contrastText);
                font-size: large;
                margin: 0px 10px;
            }
        }
    }
}

.addendum-card {
    cursor: pointer;
    background-color: #fff;
    box-shadow: shadows(1);
    transform: scale(1);
    transition: 0.2s !important;
    overflow: visible !important;
    @media (min-width: breakpoints(md)) {
        height: 220px !important;
    }
    &:hover {
        background-color: lighten(palette(grey, 100), 1%);
        box-shadow: shadows(3);
        transform: scale(1.03);
    }
    .large-content {
        margin: auto 0px !important;
        padding: 20px !important;
        @include typography(subtitle2);
    }
    .small-content {
        margin: auto 0px !important;
        padding: 20px !important;
        @include typography(body1);
    }
}
