.simulator-page {
    background-color: palette(secondary, main);
    min-height: calc(100vh - 45px);
    padding-bottom: 20px;
}

.simulator-page-heading {
    height: 45px;
    width: 100%;
    background-color: palette(secondary, main);
    color: palette(primary, contrastText);
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    .title {
        @include typography(h5);
        font-weight: 600 !important;
        padding-right: 10px;
    }
    @media (max-width: breakpoints(sm)) {
        height: auto;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.simulator-detail {
    width: 100% !important;
    .simulator-body {
        padding: 15px 8px 0px 0px !important;
    }
    .simulator-side-bar {
        --num-sidebar-displays: 1;
        overflow-x: auto;
        .description {
            max-height: 140px !important;
            margin: 10px;
            padding: 10px;
            .simulator-subtitle {
                @include typography(subtitle1);
            }
            .simulator-description {
                @include typography(body1);
                max-height: 89px !important;
                white-space: pre-line;
                overflow-y: scroll;
            }
        }
    }
    .simulator-variables-body {
        width: 100%;
        overflow-y: auto;
        .page-body {
            width: 100% !important;
            // padding: 0px 8px 0px 0px !important;
        }
    }
}

.simulator-title {
    @include typography(h5);
}

.simulator-chart {
    &.card {
        width: 100%;
    }
    .tooltip-div {
        border-radius: shape(borderRadius);
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        margin-top: -10px;
        margin-bottom: 1px;
        padding: 5px 8px;
        .month {
            @include typography(body1);
            font-weight: 700 !important;
        }
        .value {
            @include typography(body2);
        }
    }

    .year-controls {
        @extend .aligned-row;
        span:nth-child(1) {
            @extend .left;
            button {
                transform: translate(70px, -36px);
            }
        }
        span:nth-child(2) {
            @extend .right;
            button {
                transform: translate(-20px, -36px);
            }
        }
        button {
            background-color: palette(primary, main);
            color: palette(primary, contrastText);
        }
        button:hover {
            background-color: palette(primary, dark);
        }
    }
}

.simulator-output-mode {
    padding: 15px 0px 15px 5px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: (breakpoints(md)-150)) {
        padding: 15px !important;
        justify-content: center;
        flex-direction: column;
    }
}

.card-corner-button {
    position: absolute !important;
    right: 0px !important;
    top: -10px !important;
    background-color: palette(primary, main) !important;
    color: white !important;
    border-radius: 50px !important;
    z-index: 10;
    .MuiSvgIcon-root {
        font-size: 18px;
    }
}

.simulator-landing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .half-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 55%;
        background-color: palette(secondary, main);
        z-index: -1;
    }
    .heading {
        display: flex;
        flex-direction: column;
        max-width: 800px;
        margin: 30px 20px 20px;
        padding: 20px 30px;
        color: white;
        border-radius: shape(borderRadius);
        background-color: rgba(0, 0, 0, 0.1);
        border: 1.5px solid rgba(0, 0, 0, 0.1);
        .MuiTypography-root {
            text-align: center;
        }
        h2 {
            @include typography(h5);
            font-style: italic;
            font-weight: 600 !important;
            padding-bottom: 10px;
        }
        button {
            border-radius: 50px;
            height: 40px;
            width: 300px !important;
            margin: auto;
            margin-top: 15px !important;
            color: palette(grey, 800);
            background-color: palette(grey, 100);
            box-shadow: shadows(5);
        }
    }
    .list {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1300px !important;
        padding: 20px 20px;
        .aligned-row {
            padding-bottom: 10px;
        }
        .MuiToolbar-root {
            margin-bottom: 0px !important;
            color: white;
        }
    }
}

.changelogs {
    margin: 10px;
    .MuiCardHeader-root {
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        border-bottom: 1.5px solid #eeeeee;
        padding: 12px 10px 10px;
        .MuiTypography-root {
            @include typography(subtitle2);
            line-height: 1em !important;
        }
    }
    .MuiCardContent-root {
        padding: 0px !important;
        color: palette(grey, 800);
        overflow-y: auto;
        max-height: calc(75vh - 90px - 100px);
    }
    .changelog:last-child {
        border-bottom: none;
    }
    .changelog {
        padding: 10px;
        border-bottom: 1.5px solid #eeeeee;
        span {
            font-weight: 600;
        }
    }
}

.transition {
    transition: all 0.5s ease-in-out;
    &.disappear {
        transition: all 0.5s ease-in-out;
        height: 0px;
        width: 0px;
        display: none;
    }
}

.input-cell {
    form {
        max-width: none !important;
    }
    .MuiFormControl-root {
        margin: 0px !important;
        input {
            text-align: end !important;
        }
        .MuiFilledInput-root {
            border-radius: shape(borderRadius);
            padding: 0px 8px !important;
        }
        .MuiFilledInput-input {
            padding: 10px 2px !important;
        }
        .MuiFilledInput-underline:before {
            content: none !important;
        }
        .MuiFilledInput-underline:after {
            content: none !important;
        }
        .MuiInputAdornment-positionEnd {
            margin-left: 2px !important;
        }
    }
}

.simulator-year-select {
    background-color: white;
    padding: 0px 4px;
    margin-left: 10px;
    border-radius: shape(borderRadius);
    border: 1.5px solid palette(grey, 300);
    .MuiTypography-root {
        @include typography(h6);
        font-weight: 600 !important;
        padding: 2px 5px 0px;
        color: palette(primary, main);
    }
    .MuiIconButton-root {
        padding: 0px;
        color: palette(grey, 600);
    }
}
