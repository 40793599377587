.welcome-text {
    &.name {
        @include typography(subtitle1);
        font-style: italic !important;
        font-weight: 600 !important;
        color: palette(primary, main);
        padding-right: 10px;
        line-height: 1 !important;
    }
    &.date {
        @include typography(body1);
        color: palette(grey, 800);
        line-height: 1 !important;
    }
}

.bar-chart-widget {
    &.card {
        width: 100%;
    }
    .table-container {
        max-height: 50vh !important;
        .MuiTableHead-root {
            color: palette(primary, main) !important;
        }
        .MuiTableCell-stickyHeader {
            background-color: white !important;
            border-bottom: 1.5px solid palette(grey, 200);
        }
        .MuiTableRow-root {
            .MuiTableCell-root:not(:last-child) {
                border-right: 1.5px solid palette(grey, 200);
            }
        }
        .info-row {
            .MuiTableCell-root {
                @include typography(body2);
                color: #383838;
                border-top: 1.5px solid palette(grey, 200) !important;
                position: sticky;
                position: -webkit-sticky;
                bottom: 0px;
                left: 0px;
                z-index: 2;
                background-color: white;
            }
        }
    }
    .payment-button {
        font-size: small;
    }
    .tooltip-div {
        border-radius: shape(borderRadius);
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        margin-top: -10px;
        margin-bottom: 1px;
        padding: 5px 8px;
        .study {
            @include typography(body1);
            font-weight: 700 !important;
        }
        .division {
            @include typography(body2);
        }
    }
}

.dashboard {
    width: 100%;
    display: flex;
    flex-direction: row;

    @media (max-width: (breakpoints(md))) {
        flex-direction: column;
        .dashboard-body {
            width: auto !important;
            max-height: none !important;
            overflow-y: hidden !important;
        }
        .dashboard-side-bar {
            width: auto !important;
            .widget-list {
                height: auto !important;
                .card-content {
                    max-height: 200px !important;
                }
            }
        }
    }

    .dashboard-body {
        width: 79vw;
        max-height: calc(100vh - 90px);
        overflow-y: auto;
        .page-body {
            width: 100% !important;
            padding-right: 8px !important;
        }
    }
    .dashboard-side-bar {
        --num-sidebar-displays: 1;
        width: 21vw;
        min-width: 250px;
        overflow-x: auto;
        .effort-link {
            min-height: 0px !important;
            height: 140px !important;
        }
        &.with-effort-link {
            .quick-access-widget {
                @media (min-width: breakpoints(md)) {
                    height: calc((100vh - 90px - 140px) / var(--num-sidebar-displays));
                }
                .card-content {
                    max-height: calc(
                        (100vh - 90px - 140px - (58px * var(--num-sidebar-displays))) / var(--num-sidebar-displays)
                    );
                }
            }
        }

        .quick-access-widget {
            @media (min-width: breakpoints(md)) {
                min-height: 150px;
                height: calc((100vh - 90px) / var(--num-sidebar-displays));
            }
            .card-header {
                position: -webkit-sticky;
                position: sticky;
                top: 0px;
            }
            .card-content {
                overflow-y: auto;
                max-height: calc((100vh - 90px - (58px * var(--num-sidebar-displays))) / var(--num-sidebar-displays));
            }
        }
    }
}

.widget-header {
    padding: 10px;
    border-bottom: 1.5px solid palette(grey, 200);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .header-text {
        @include typography(subtitle1);
        padding-right: 10px;
        font-weight: 600 !important;
    }
}

.dashboard-header {
    @media (max-width: breakpoints(md)) {
        flex-direction: column;
        .left {
            justify-content: center !important;
            flex-direction: column;
        }
        .right {
            display: none !important;
        }
    }
}

.widget-bar-chart {
    .recharts-label {
        color: palette(grey, 400) !important;
    }
}

.responsive-filter {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 16px 20px 0px;
    @media (min-width: breakpoints(md)) {
        display: none !important;
    }
}
