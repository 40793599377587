.user-detail-option-header {
    border-style: solid;
    border-width: 0 0 0.05em 0;
    border-color: palette(text, hint);
}

.user-detail-option-tab {
    @include typography(subtitle1);
    color: palette(primary, main) !important;
    background-color: palette(background, default) !important;
    box-shadow: none !important;
    border-style: solid !important;
    border-width: 0 0 0.25em 0 !important;
    border-radius: 0 !important;
    margin-right: 10px !important;
}

.user-detail-main {
    padding: 2em 0 0 0;
}

.user-role-table {
    max-height: 300px;
    overflow-y: auto;
}

.not-selected-user-detail-option-tab {
    @extend .user-detail-option-tab;
    border-style: hidden !important;
}

.selected-user-detail-option-tab {
    @extend .user-detail-option-tab;
    border-color: palette(primary, main) !important;
}

.statistics-table-header {
    background-color: palette(primary, main);
    color: palette(primary, contrastText);
    padding: 0.5em !important;
    padding-left: 1em !important;
}

.statistics-table-header-cell {
    padding: 6px 8px;
    font-size: 0.95rem;
    font-weight: 600;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-align: center;
}

.statistics-table-header-cell-disabled {
    @extend .statistics-table-header-cell;
    color: rgba(0, 0, 0, 0.26);
}

.statistics-table-container {
    max-height: 15em;
    width: 91.5em !important;
}

.statistics-table-study-cell {
    background-color: palette(primary, light);
}

.statistics-table-effort-log-cell {
    border-color: palette(text, hint);
    text-align: center !important;
}

.statistics-table-productivity-cell {
    border-bottom-style: solid !important;
    border-bottom-color: palette(background, default) !important;
    border-bottom-width: 0.15rem !important;
    text-align: end !important;
}

.statistics-table-productivity-cell-negative {
    @extend .statistics-table-productivity-cell;
    color: palette(error, main) !important;
}

.statistics-table-effort-log-cell-input {
    input {
        padding: 10px 14px;
    }
}

.statistics-table-month-header-cell {
    width: 100%;
}

.statistics-table-contrast-row {
    background-color: palette(grey, 200);
}

.statistics-table-year-select {
    color: palette(primary, contrastText);
    font-size: 1.5em;
    text-align: center;

    span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
        font-size: 1.25rem;
    }

    button span svg {
        font-size: 1.75rem;
    }
}

.monthly-effort-scrollable {
    overflow-y: scroll;
    max-height: 20em;
}



.salary-table-container {
    max-height: 20.5em;
}

.salary-table-header-cell {
    background-color: palette(primary, main) !important;
    color: palette(primary, contrastText) !important;
}

.salary-table-header-cell[data-header-type="effectiveDate"] {
    @extend .salary-table-header-cell;
    border-radius: 3px 0 0 0;
}

.salary-table-header-cell[data-header-type="fringe"] {
    @extend .salary-table-header-cell;
    border-radius: 0 3px 0 0;
}
