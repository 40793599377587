.modal-form {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 15px !important;
    .detail-form {
        margin: auto !important;
    }
}

.detail-form {
    width: 100% !important;
    background-color: palette(grey, 50) !important;

    &.sm {
        max-width: breakpoints(sm) !important;
    }
    &.md {
        max-width: breakpoints(md) !important;
    }
    &.lg {
        max-width: breakpoints(lg) !important;
    }

    .MuiCardHeader-root {
        font-size: 10px;
        padding: 10px 1em;
    }
    .MuiCardHeader-title {
        font-size: 2em !important;
        font-weight: 500 !important;
    }
    .MuiCardHeader-action {
        padding-top: 6px;
        padding-right: 6px;
        .MuiButtonBase-root {
            padding: 5px;
        }
    }
    .MuiCardContent-root {
        border-top: #e2e2e2 solid 1px;
        border-bottom: #e2e2e2 solid 1px;
        max-height: calc(100vh - 160px) !important;
        overflow-y: auto !important;
    }
    .MuiCardActions-root {
        padding: 10px 1em;
    }
    .MuiChip-root {
        height: 25px !important;
        border-radius: 25px;
    }

    &.short {
        .MuiCardContent-root {
            max-height: calc(100vh - 250px) !important;
        }
    }
}

.line-item-form {
    .MuiCardContent-root {
        @extend .flex-center;
        flex-direction: column;
        .MuiTableContainer-root {
            width: 80% !important;
            .label {
                font-weight: 600;
                color: palette(grey, A600);
                min-width: 120px;
            }
            .value {
                color: palette(text, primary) !important;
            }
        }
    }
}

.account-form {
    .section-labels {
        color: palette(grey, 500) !important;
    }
    .section-borders {
        border: 1px solid palette(grey, 500) !important;
    }
    .MuiFormLabel-root {
        font-weight: 700 !important;
        color: palette(primary, main) !important;
    }
    .modal-radio-buttons {
        margin-left: 20px;
        .info-tooltip {
            display: flex;
        }
    }
    .contact-section-border {
        border-bottom: 1px solid palette(grey, A100) !important;
    }
    .contact-error {
        color: palette(error, dark) !important;
    }
    .contact-error-border {
        border: 1px solid palette(error, dark) !important;
    }
    .contact-delete-button {
        color: palette(error, dark);
        margin-right: 10px;
    }
    .basic-information-padding {
        padding-top: 10px;
    }
    .other-section-padding {
        padding-top: 20px;
    }
    .add-contact-button {
        margin: 10px;
        .button-border {
            border-bottom: 1px solid palette(secondary, main) !important;
            display: flex;
            .icon {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
            .bold-text {
                font-weight: 900;
            }
        }
    }
}

.form-section {
    &.not-first {
        padding-top: 25px;
    }
    .title {
        text-transform: uppercase !important;
        font-size: 16px;
        font-weight: 600;
        color: palette(grey, A400);
        padding-bottom: 5px;
        &.error {
            color: palette(error, main);
        }
    }
    .body {
        margin: 0px !important;
        border: 1.5px palette(grey, A100) solid;
        background-color: palette(grey, A50);
        &.error {
            background-color: lighten(palette(error, dark), 51);
            border-color: palette(error, main);
        }
    }
    .single-employee-input {
        padding: 20px 10px !important;
    }
    .multiple-switch {
        padding-bottom: 5px;
    }
    .procedures {
        padding: 15px 20px;
    }
}
