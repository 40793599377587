.paper-margin-padding {
    height: 100%;
    margin: 20px;
    padding: 30px;
    @media (min-width: breakpoints(md)) {
        padding: 70px;
    }
}

// The following are used in:
// Login, ChangePassword, ForgotPassword, and SignUp
.card-heading {
    @include typography(h3);
    color: palette(primary, dark);
    padding-bottom: 1.5rem;
}
.card-button {
    margin-top: 1rem !important;
}
.option-links {
    margin-top: 2rem;
}