.search-bar {
    padding: 5px;
    .search-field {
        margin: auto !important;
        padding-right: 5px !important;
        .MuiInputBase-input {
            padding: 6px 10px !important;
        }
    }
    .search-button {
        min-width: 0px !important;
        padding: 3.05px 5px !important;
    }
}