.division-statistics-table {
    background-color: white;
    border-radius: shape(borderRadius);
    padding: 10px;

    .MuiTableContainer-root {
        max-height: 65vh;
    }
    .header-cell {
        background-color: palette(background, default);
        text-align: right;
        border: 1px solid palette(grey, 300);
        padding-right: 8px;
        &.background {
            background-color: palette(background, paper) !important;
            z-index: 3;
            box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.05);
        }
        &.button {
            padding: 0;
            button {
                padding: 0;
                width: 100%;
                height: 100%;
                span {
                    justify-content: left;
                    text-align: left;
                    padding-left: 10px;
                    span {
                        text-align: left;
                        padding: 0;
                    }
                }
            }
        }
    }

    .header-cell[data-disabled="true"] {
        @extend .header-cell;
        color: rgba(0, 0, 0, 0.26) !important;
    }

    .header-cell[data-highlight="true"] {
        border: 1px solid palette(primary, dark);
        background-color: palette(primary, main);
        color: palette(primary, contrastText);
        -webkit-transition: all 0.25s ease-in-out;
        -moz-transition: all 0.25s ease-in-out;
        -o-transition: all 0.25s ease-in-out;
        -ms-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
        button {
            color: palette(primary, contrastText);
        }
    }

    .sub-header-cell {
        background-color: palette(primary, main);
        color: palette(primary, contrastText);
        white-space: nowrap;
        position: sticky;
        top: 48px;
        z-index: 3;
    }

    .sub-header-cell[data-mode="activity"] {
        @extend .sub-header-cell;
        background-color: palette(accent, greenBackground);
        color: palette(accent, greenText);
    }

    .sub-header-cell[data-mode="cost"] {
        @extend .sub-header-cell;
        background-color: palette(accent, yellowBackground);
        color: palette(accent, yellowText);
    }

    .sub-header-cell[data-mode="productivity"] {
        @extend .sub-header-cell;
        background-color: palette(accent, blueBackground);
        color: palette(accent, blueText);
    }

    .sticky {
        position: -webkit-sticky;
        position: sticky;
        text-overflow: ellipsis;
        overflow: hidden;
        z-index: 3;
    }

    --col-0-width: 12rem;
    --col-1-width: 7rem;

    .sticky[data-column="0"] {
        @extend .sticky;
        left: 0;
        min-width: var(--col-0-width);
        max-width: var(--col-0-width);
    }

    .sticky[data-column="1"] {
        @extend .sticky;
        left: var(--col-0-width);
        min-width: var(--col-1-width);
        max-width: var(--col-1-width);
    }

    .sticky[data-column="2"] {
        @extend .sticky;
        left: calc(var(--col-0-width) + var(--col-1-width));
        min-width: var(--col-1-width);
        max-width: var(--col-1-width);
        box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.05);
    }

    .data-cell {
        background-color: palette(background, paper);
        border: 1px solid palette(background, default);
        white-space: nowrap;
        &.button {
            padding: 0;
            button {
                padding: 0;
                width: 100%;
                height: 100%;
                justify-content: flex-end;
                border-radius: 0px !important;
                span {
                    padding-right: 5px;
                    span {
                        @include typography(body2);
                        padding-right: 0;
                    }
                }
            }
            button:hover {
                box-shadow: 0px 0px 3px 1px palette(primary, main);
                -webkit-transition: box-shadow 0.25s ease;
                -moz-transition: box-shadow 0.25s ease;
                -o-transition: box-shadow 0.25s ease;
                -ms-transition: box-shadow 0.25s ease;
                transition: box-shadow 0.25s ease;
            }
        }
    }

    .data-cell[data-disabled="true"] {
        @extend .data-cell;
        color: rgba(0, 0, 0, 0.26) !important;
    }
}

.mode-toggle-group {
    box-shadow: shadows(1);

    .MuiToggleButton-root {
        text-transform: none !important;
        border-style: solid !important;
        border-width: 1.5px !important;
        padding: 5px 15px !important;
        font-weight: 500;
    }
    .MuiToggleButton-root[aria-pressed="false"] {
        background-color: palette(background, paper) !important;
        border-color: palette(grey, 300) !important;
        color: palette(grey, 800) !important;
    }
    .MuiToggleButton-root[aria-pressed="true"] {
        background-color: palette(primary, main) !important;
        color: palette(primary, contrastText) !important;
    }
    .MuiToggleButton-root[aria-pressed="true"][data-mode="activity"] {
        background-color: palette(accent, greenBackground) !important;
        color: palette(accent, greenText) !important;
    }
    .MuiToggleButton-root[aria-pressed="true"][data-mode="cost"] {
        background-color: palette(accent, yellowBackground) !important;
        color: palette(accent, yellowText) !important;
    }
    .MuiToggleButton-root[aria-pressed="true"][data-mode="productivity"] {
        background-color: palette(accent, blueBackground) !important;
        color: palette(accent, blueText) !important;
    }
    .MuiToggleButton-label {
        line-height: 1;
        padding: 2px 0px 0px;
    }
}
