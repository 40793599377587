.contract-accounting-card {
    background-color: #fff;
    box-shadow: shadows(1);
    transform: scale(1);
    transition: 0.2s !important;
    border: 2px solid palette(grey, 200) !important;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    @media (min-width: breakpoints(xs)) {
        min-height: 200px !important;
    }
    overflow: visible !important;
    .account-information {
        width: 100%;
    }
    .account-section {
        padding: 20px;
    }
    .account-responses {
        margin-left: 20px;
    }
    .none-label {
        color: palette(grey, 500);
    }
    .address-text {
        margin-left: 20px;
        white-space: pre-line;
    }
    .action-required-label {
        color: palette(error, main) !important;
    }
}
.action-required-card {
    @extend .contract-accounting-card;
    justify-content: center;
    @media (min-width: breakpoints(xs)) {
        min-height: 200px !important;
    }
    @media (min-width: breakpoints(sm)) {
        min-height: 337px !important;
    }
}
.card-label {
    font-weight: 600 !important;
}
.action-required-button {
    animation: pulse 3s infinite;
}
.edit-account-button {
    justify-content: flex-end !important;
    padding: 0px !important;
    height: 25px;
    min-width: 0px !important;
}
.account-card-label {
    display: flex;
    .required-asterisk {
        color: palette(error, dark) !important;
    }
}
.incomplete-field {
    border-color: palette(error, main) !important;
}
.mailing-address-label {
    color: palette(gray, 600);
}