.division-section-title {
    background-color: palette(primary, main) !important;
    color: white !important;
}
.account-summary {
    width: 100%;
    padding: 12px;
    .heading {
        @include typography(h6);
        font-weight: 600 !important;
    }
    &.condensed {
        .info-line {
            p:nth-child(2) {
                text-align: end;
            }
        }
    }
    .info-line {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: palette(primary, main);
        .MuiTypography-root {
            font-weight: 600 !important;
        }
        .email {
            word-break: break-all;
        }
        > p:first-child {
            color: palette(grey, A600);
            padding: 4px 0px 4px 0px;
        }
        p:not(:first-child) {
            padding-left: 10px;
        }
        &.spaced {
            justify-content: space-between;
        }
        &.padding {
            padding-top: 8px;
        }
        .contact-attribute {
            p:not(:first-child) {
                padding-left: 0px;
            }
            padding-left: 15px;
            padding-bottom: 2px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            .MuiSvgIcon-root {
                font-size: 18px;
                margin-right: 10px;
            }
            &.padding-top {
                padding-top: 10px;
            }
        }
    }
    .MuiGrid-item:first-child {
        border-right: 1.5px solid palette(grey, 300);
        @media (max-width: breakpoints(sm)) {
            border-bottom: 1.5px solid palette(grey, 300);
            border-right: none;
        }
    }
    .MuiTableCell-head {
        height: 32px !important;
        font-weight: 600;
        color: palette(grey, A600);
    }
    .MuiButton-root {
        height: 25px !important;
    }
    .costs-table {
        margin-top: 10px;
        .MuiTable-root {
            border-radius: shape(borderRadius) !important;
            background-color: #fff;
            box-shadow: 0px 0px 0px 2px palette(grey, 200) inset !important;
        }
        .MuiTableRow-root {
            outline: 1px solid palette(grey, 200) !important;
            .MuiTableCell-head {
                color: palette(grey, A600);
            }
            .MuiTableCell-head:first-child {
                border-right: 2px solid palette(grey, 200) !important;
                font-weight: 700;
            }
        }
        .MuiTableCell-head {
            height: 35px !important;
        }
    }
    .MuiTableCell-root {
        padding: 0px !important;
    }
}

.page-tabs {
    position: relative;
    .log-payment {
        .MuiButton-root {
            z-index: 1;
        }
    }
    @media (max-width: (breakpoints(sm)+150)) {
        .log-payment {
            padding-top: 20px;
            display: flex;
            width: 100%;
            align-items: flex-end;
            justify-content: flex-end;
        }
    }
    @media (min-width: (breakpoints(sm)+150)) {
        .MuiButton-root {
            position: absolute;
            bottom: 10px;
            right: 0;
        }
    }
}

.line-item-filters {
    @media (max-width: breakpoints(md)) {
        display: flex;
        justify-content: center;
    }
    .filter-label {
        margin-right: 20px;
        font-weight: 600 !important;
    }
}

.table-options-info {
    padding: 0px 0px 10px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: (breakpoints(md)-150)) {
        padding: 25px 0px 15px !important;
        justify-content: center;
        flex-direction: column;
        .toggles {
            order: 1;
        }
        .generate-date {
            order: 0;
            padding-bottom: 15px;
        }
    }
    @media (max-width: breakpoints(sm)) {
        .generate-date {
            display: flex;
            flex-direction: column;
            padding-bottom: 20px;
        }
    }
    .toggles {
        .MuiTypography-root {
            font-weight: 600 !important;
            padding-right: 10px;
            color: palette(primary, main);
            line-height: 1;
        }
    }
    .generate-date {
        .MuiTypography-root {
            padding-top: 4px;
            padding-left: 5px;
            line-height: 1;
            color: palette(grey, 700);
        }
        .MuiTypography-subtitle1 {
            padding-top: 4px;
            padding-left: 8px;
            line-height: 1;
            font-weight: 600;
            color: palette(primary, main);
        }
        .MuiButton-root {
            margin-left: 10px;
            padding-left: 5px;
        }
        .MuiButton-startIcon {
            margin-left: 4px;
            margin-right: 4px;
        }
    }
}

.highlight-cards {
    display: flex;
    align-items: stretch;
    .MuiGrid-item {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .MuiPaper-root {
        // display: flex;
        height: 100%;
    }
}

.invoice-list {
    padding: 20px;

    .summary-card-header {
        @include typography(h6);
        padding-bottom: 5px;
    }

    .summary-card {
        padding-bottom: 15px;
    }

    .invoice-table {
        padding-top: 15px;
    }
}

.scrollable-sidebar {
    overflow-y: auto;
    height: calc(100vh - 92px);

    .MuiFormControlLabel-root {
        padding: 0 0px 0px 10px !important;
    }
    .MuiCheckbox-root {
        padding: 4px;
    }

    .MuiOutlinedInput-input {
        padding: 7px 14px;
    }
}

.invoice-detail {
    width: 100% !important;
    margin: 0px !important;
    padding: 25px 50px;
    .title {
        @include typography(body1);
        font-weight: 600 !important;
    }
    .info-card {
        .info-header {
            padding-bottom: 10px;
            min-height: 41px;
            .link {
                background-color: palette(background, default);
                color: palette(secondary, main);
                font-weight: 800;
            }
            button {
                background-color: palette(background, paper);
                padding: 2px 10px 2px 10px !important;
                font-size: small;
            }
        }
        .info-body {
            padding: 15px;
            .MuiGrid-item {
                padding-bottom: 3px;
            }
            .label {
                font-size: smaller;
                font-weight: 600;
                color: palette(grey, A400);
            }
            .value {
                padding-top: 3px;
                @include typography(body1);

                button span {
                    font-size: small;
                }
                .status-chip p {
                    font-size: small !important;
                }
                &.transparent {
                    color: palette(grey, 500);
                }
            }
            .label-icon-div {
                display: flex;
                align-items: flex-end;
                .edit-desc {
                    padding: 2px;
                    margin-left: 15px;
                }
            }
        }
    }
}

.payment-tooltip {
    white-space: pre-line;
    a {
        color: white !important;
    }
}

.payments-for-invoice {
    @extend .flex-center;
    margin: 15px !important;

    .payment-table-container {
        max-height: 20.5em;
    }

    .payment-table-header-cell {
        background-color: palette(primary, main) !important;
        color: palette(primary, contrastText) !important;
        padding: 0px 10px !important;
    }

    .payment-table-body-cell {
        @extend .body-cell;
        padding: 0px 10px !important;
    }
}
