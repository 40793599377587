.variables {
    margin: 10px;
    overflow: visible !important;
    .button-container {
        @extend .flex-center;
        border-bottom: 1.5px solid #eeeeee;
        .update-simulator {
            padding: 4px 50px 2px !important;
            margin: 5px 0px;
        }
    }
    .update-simulator {
        @extend .flex-center;
        padding: 4px 8px 2px !important;
        $button-color: #b9bfca;
        background-color: $button-color;
        color: darken(white, 5%);
        .MuiTypography-root {
            text-transform: uppercase;
            font-weight: 600 !important;
        }
        .MuiButton-label {
            line-height: normal;
        }
        &.ready {
            @extend .contained-button-pulse;
            cursor: pointer;
            border: 1.5px solid palette(primary, dark);
            background-color: palette(primary, main);
            color: white;
            position: relative;
            animation: double-pulse-primary-small 2.5s ease-in-out infinite;
            .MuiTypography-root {
                animation: pulse-size 1s ease-in-out infinite;
            }
        }
    }
    &.expanded {
        .header {
            padding: 0px 10px;
            height: 45px;
            .title {
                @include typography(subtitle2);
                line-height: 1em !important;
                padding-right: 10px;
            }
            .MuiFormControlLabel-root {
                margin: 0px;
            }
            .MuiButton-root {
                @include typography(body2);
                font-weight: 600 !important;
                text-transform: uppercase;
                padding: 6px 10px 12px;
                margin-top: 8px;
                color: palette(grey, 600) !important;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                &.Mui-disabled {
                    color: palette(primary, main) !important;
                    background-color: palette(grey, 200) !important;
                }
            }
        }
        .MuiCardContent-root {
            background-color: palette(grey, 200) !important;
            max-height: none !important;
        }
        .features {
            padding: 0px 10px;
            height: 45px;
            .MuiFormControlLabel-root {
                margin: 0px;
            }
            .tabs .MuiButton-root {
                @include typography(body2);
                font-weight: 600 !important;
                text-transform: uppercase;
                padding: 6px 10px 12px;
                margin-top: 8px;
                color: palette(grey, 600) !important;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                &.Mui-disabled {
                    color: palette(primary, main) !important;
                    background-color: palette(grey, 200) !important;
                }
            }
        }
    }
    .MuiCardHeader-root {
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        border-bottom: 1.5px solid #eeeeee;
        padding: 12px 10px 10px;
        .MuiTypography-root {
            @include typography(subtitle2);
            line-height: 1em !important;
        }
    }
    .MuiCardContent-root {
        padding: 0px !important;
        color: palette(grey, 800);
        overflow-y: auto;
        @media (max-width: breakpoints(xl)-200) {
            max-height: 500px !important;
        }
        max-height: 650px !important;
    }
    .multi-header {
        @extend .flex-center;
        flex-direction: row;
        .section-header {
            width: 50%;
        }
    }
    .section-header {
        height: 30px !important;
        background-color: palette(grey, A50);
        border-top: 1.5px solid palette(grey, 300);
        border-bottom: 1.5px solid palette(grey, 300);
        text-transform: uppercase;
        font-weight: 600;
        @extend .flex-center;
        color: palette(primary, main);
        &.inactive {
            cursor: pointer !important;
            color: palette(grey, 500);
            background-color: palette(grey, 300);
            border-top: 1.5px solid darken(palette(grey, 300), 5%);
            border-bottom: 1.5px solid darken(palette(grey, 300), 5%);
        }
        &:last-child {
            border-left: 1.5px solid darken(palette(grey, 300), 5%);
        }
        &.standalone {
            background-color: white;
            border-top: none;
        }
    }
    .slider-item {
        @extend .flex-center;
        flex-direction: column;
        width: 100%;
        border-bottom: 1.5px solid palette(grey, 300);
        padding: 10px;
        &.no-border {
            border-bottom: none !important;
        }
        .subtext {
            display: flex;
            flex-direction: row;
            .MuiTypography-root {
                @include typography(body2);
                color: palette(grey, 700);
            }
            .MuiTypography-root:last-child {
                @include typography(body2);
                color: palette(primary, main);
                font-weight: 600 !important;
                padding-left: 5px;
            }
        }
        .MuiSlider-root {
            width: 90%;
            padding: 8px 0px !important;
            .MuiSlider-rail {
                height: 5px;
            }
            .MuiSlider-track {
                height: 5px;
                background-color: palette(secondary, main);
            }
            .MuiSlider-thumb {
                height: 14px;
                width: 14px;
            }
            .MuiSlider-mark {
                height: 18px;
                opacity: 0.5;
                border-radius: 0px;
                background-color: palette(primary, main);
            }
        }
        .slider-input {
            margin: 0px !important;
            input {
                text-align: end !important;
            }
            .MuiFilledInput-root {
                border-radius: shape(borderRadius);
                padding: 0px 8px !important;
            }
            .MuiFilledInput-input {
                padding: 10px 2px !important;
            }
            .MuiFilledInput-underline:before {
                content: none !important;
            }
            .MuiFilledInput-underline:after {
                content: none !important;
            }
            .MuiInputAdornment-positionEnd {
                margin-left: 2px !important;
            }
        }
        .stepper-button {
            z-index: 1;
            border-radius: 50px;
            background-color: white;
            border: 1.5px solid palette(grey, 400);
            padding: 0px;
            margin-bottom: 18px;
            .MuiSvgIcon-root {
                font-size: 1.2rem;
            }
        }
    }
}

.lock-switch {
    .MuiSwitch-switchBase {
        color: white;
    }
    .MuiSwitch-track {
        opacity: 1;
        background-color: palette(grey, 500);
    }
    .MuiSwitch-colorSecondary.Mui-checked {
        color: white;
    }
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        opacity: 1;
        background-color: palette(success, dark);
    }
}

.detailed-employees-collapsed {
    @include typography(body1);
    padding: 10px 10px 0px 10px;
    span {
        font-weight: 600;
    }
}

.detailed-employees-expanded {
    width: 100% !important;
    margin: 0px !important;
    form {
        max-width: none !important;
    }
    .employee-detail {
        position: relative;
        width: 100%;
        overflow: visible;
        .employee-header {
            padding: 10px;
            position: relative;
            border-bottom: 1px solid palette(grey, 300);
            .MuiIconButton-root {
                position: absolute;
                right: -10px !important;
                top: -10px !important;
                background-color: palette(grey, 600) !important;
                color: white;
                .MuiSvgIcon-root {
                    font-size: 15px;
                }
            }
            .MuiFormControl-root {
                margin: 0px;
            }
            .MuiInput-underline:before {
                // border-bottom: 1px solid white;
            }
        }
        .simulated-chip {
            background-color: #f0aa2d;
            color: white;
            font-size: medium;
            font-weight: 600;
        }
        .employee-content {
            margin: 0px;
            width: 100%;
            height: 275px !important;
            padding: 10px;
            overflow-y: auto;
        }
        .MuiCardActions-root {
            border-top: 1px solid palette(grey, 300);
        }
        .employee-salary-logs {
            .MuiChip-root {
                width: 100%;
                padding: 5px !important;
                margin-top: 8px !important;
                cursor: pointer !important;
                background-color: palette(grey, 100);
                border: 1.5px solid palette(grey, 300);
                .MuiChip-label {
                    @include typography(body2);
                }
            }
            .MuiIconButton-root {
                background-color: palette(grey, 100);
                border: 1.5px solid palette(grey, 300);
                .MuiSvgIcon-root {
                    font-size: 18px;
                }
            }
        }
    }

    .add-employee {
        @include typography(h6);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        cursor: pointer;
        width: 100%;
        height: 100%;
        transform: translateY(0px);
        transition: all 0.2s ease-in-out;
        min-height: 100px;
        &:hover {
            transform: scale(0.9);
        }
    }

    .MuiTextField-root {
        .MuiIconButton-root {
            padding: 10px;
        }
        .MuiSvgIcon-root {
            font-size: 20px;
        }
        .MuiOutlinedInput-adornedEnd {
            padding-right: 0px !important;
        }
        .MuiOutlinedInput-inputAdornedEnd {
            padding-right: 0px !important;
        }
        .MuiInputAdornment-positionEnd {
            margin: 0px;
        }
    }
}

.employee-type-toggle {
    .MuiToggleButtonGroup-root {
        margin: 8px 0px 10px;
        width: 100%;
        .MuiToggleButton-root {
            text-transform: none !important;
            border-style: solid !important;
            border-width: 1.5px !important;
            padding: 5px 15px !important;
            font-weight: 500;
            width: 100%;
            .MuiTypography-root {
                font-weight: 600 !important;
            }
            &[aria-pressed="false"] {
                background-color: palette(grey, 100) !important;
                border-color: palette(grey, 300) !important;
                color: palette(grey, 500) !important;
            }
            &[aria-pressed="true"] {
                background-color: palette(primary, main) !important;
                color: palette(primary, contrastText) !important;
            }
        }
    }
}

.MuiSlider-markLabel {
    &[data-index="1"] {
        background-color: palette(primary, main);
        color: white;
        border-radius: 50px;
        padding: 3px 8px 1px;
        margin-top: -3px;
        z-index: 1;
        font-weight: 600;
    }
}

.employee-simulation-mode {
    margin: 12px;
    .MuiCardHeader-root {
        padding: 6px 4px 4px;
        .MuiTypography-root {
            @include typography(body1);
            text-align: center;
            text-transform: uppercase;
            font-weight: 600 !important;
        }
    }
    .content {
        background-color: white;
        padding: 8px 10px;
        .MuiFormControlLabel-root {
            margin: 0px;
        }
        .MuiRadio-root {
            padding: 4px 6px 4px 4px;
        }
    }
}

.simulator-effort-table {
    background-color: white;
    border-radius: shape(borderRadius);

    .effort-header {
        @include typography(body1);
        font-weight: 600 !important;
        text-transform: uppercase;
        border-bottom: 2px solid #eeeeee;
        padding: 5px 10px;
    }
    .effort-body {
        padding: 10px;
    }

    .condensed-year-select {
        .MuiTypography-root {
            padding-top: 2px;
        }
        .MuiIconButton-root {
            padding: 2px !important;
        }
    }

    .MuiTableContainer-root {
        max-height: 65vh;
    }
    .header-cell {
        background-color: palette(background, default);
        text-align: right;
        border: 1px solid palette(grey, 300);
        padding-right: 8px;
        &.background {
            background-color: palette(background, paper) !important;
            z-index: 3;
            box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.05);
        }
        &.button {
            padding: 0;
            button {
                padding: 0;
                width: 100%;
                height: 100%;
                span {
                    justify-content: left;
                    text-align: left;
                    padding-left: 10px;
                    span {
                        text-align: left;
                        padding: 0;
                    }
                }
            }
        }
    }

    .header-cell[data-disabled="true"] {
        @extend .header-cell;
        color: rgba(0, 0, 0, 0.26) !important;
    }

    .header-cell[data-highlight="true"] {
        border: 1px solid palette(primary, dark);
        background-color: palette(primary, main);
        color: palette(primary, contrastText);
        -webkit-transition: all 0.25s ease-in-out;
        -moz-transition: all 0.25s ease-in-out;
        -o-transition: all 0.25s ease-in-out;
        -ms-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
        button {
            color: palette(primary, contrastText);
        }
    }

    .sticky {
        position: -webkit-sticky;
        position: sticky;
        text-overflow: ellipsis;
        z-index: 3;
    }

    --col-0-width: 10rem;
    --col-1-width: 7rem;

    .sticky[data-column="0"] {
        @extend .sticky;
        left: 0;
        min-width: var(--col-0-width);
        max-width: var(--col-0-width);
        padding-right: 10px;
    }
    .sticky[data-column="1"] {
        @extend .sticky;
        left: var(--col-0-width);
        min-width: var(--col-1-width);
        max-width: var(--col-1-width);
    }

    .data-cell {
        background-color: palette(background, paper);
        border: 1px solid palette(background, default);
        white-space: nowrap;
        &.button {
            padding: 0;
            button {
                padding: 0;
                width: 100%;
                height: 100%;
                justify-content: flex-end;
                border-radius: 0px !important;
                span {
                    padding-right: 5px;
                    span {
                        @include typography(body2);
                        padding-right: 0;
                    }
                }
            }
            button:hover {
                box-shadow: 0px 0px 3px 1px palette(primary, main) !important;
                -webkit-transition: box-shadow 0.25s ease;
                -moz-transition: box-shadow 0.25s ease;
                -o-transition: box-shadow 0.25s ease;
                -ms-transition: box-shadow 0.25s ease;
                transition: box-shadow 0.25s ease;
            }
        }
    }

    .data-cell[data-disabled="true"] {
        @extend .data-cell;
        color: rgba(0, 0, 0, 0.26) !important;
    }
}
