.collapsible-panel {
    display: flex;
    .MuiSvgIcon-root {
        font-size: 1.35rem;
    }
    .MuiListItemIcon-root {
        min-width: 35px;
        color: inherit !important;
    }
    .side-bar {
        width: 225px;
        background-color: palette(grey, 50);
        transition: width 200ms;
        box-shadow: shadows(2);
        z-index: 1181;
        .MuiList-root {
            z-index: 1182;
            position: sticky !important;
            top: 45px;
            bottom: 0;
            padding: 0px !important;
            .MuiListItem-root {
                color: palette(grey, 600) !important;
            }
            .MuiListItemText-root {
                display: block;
                transition-delay: 3s;
                transition-property: display;
            }
            .toggle {
                height: 47px !important;
                justify-content: center;
                background-color: #fff !important;
                position: sticky !important;
                top: 45px;
                z-index: 1183;
                border-bottom: 2px solid #ddd !important;
                &:hover {
                    background-color: palette(grey, 100) !important;
                }
                .MuiListItemText-root {
                    flex: none;
                    padding-left: 10px;
                }
                .MuiSvgIcon-root {
                    transform: rotate(-135deg);
                    color: inherit;
                }
            }
            #side-add-new-contract {
                height: 45px !important;
                justify-content: center;
                color: #fff !important;
                background-color: palette(primary, main);
                border: 2px solid palette(primary, dark) !important;
                &:hover {
                    background-color: darken(palette(primary, main), 3%) !important;
                }
                .MuiListItemText-root {
                    flex: none;
                    padding-left: 10px;
                }
            }
            .option {
                text-decoration: none;
                .MuiListItem-root {
                    height: 65px !important;
                    border-bottom: 2px solid #ddd !important;
                }
            }
            .active {
                &.option .MuiListItem-root {
                    background-color: palette(grey, 200) !important;
                    color: palette(primary, main) !important;
                    .MuiTypography-root {
                        font-weight: 700 !important;
                    }
                }
            }
        }

        &.collapsed {
            width: 55px;
            .MuiListItemText-root {
                display: none !important;
            }
            .toggle .MuiSvgIcon-root {
                transform: rotate(45deg);
            }
            .browsing-options {
                visibility: hidden;
            }
        }
    }
    .spacer {
        display: flex;
        border-bottom: 2px solid #ddd !important;
    }
    .MuiSelect-root {
        padding: 10px;
    }
    .filler {
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 225px;
        background-color: palette(grey, 50);
        transition: width 200ms;
        &.collapsed {
            width: 55px;
        }
    }
    .browsing-options {
        .section-title {
            padding-top: 12px;
            padding-bottom: 12px;
            @include typography(body1);
            font-weight: 600 !important;
            .MuiTypography-root {
                font-weight: 600 !important;
            }
            color: palette(grey, A700) !important;
            background-color: palette(grey, 200) !important;
            .MuiSvgIcon-root {
                font-size: medium !important;
                color: palette(grey, 500);
                margin-left: 10px;
            }
        }
        .sub-section-title {
            @include typography(body1);
            font-weight: 600;

            .MuiSvgIcon-root {
                font-size: medium !important;
                color: palette(grey, 500);
                margin-left: 5px;
            }
        }
    }

    .top-bar {
        width: 100%;
        background-color: #fff !important;
        box-shadow: shadows(1);
        text-align: center;
        .MuiList-root {
            padding: 0;
        }
        .selected-item {
            font-size: 16px;
            color: palette(primary, main) !important;
            background-color: palette(grey, 200) !important;
            .MuiTypography-root {
                font-weight: 700 !important;
            }
        }
        .option {
            text-decoration: none;
            .MuiListItem-root {
                color: palette(grey, 600) !important;
                border-top: 1px solid palette(grey, 300) !important;
            }
        }
        .active {
            &.option .MuiListItem-root {
                background-color: palette(grey, 200) !important;
                color: palette(primary, main) !important;
                .MuiTypography-root {
                    font-weight: 700 !important;
                }
            }
        }
    }
}
