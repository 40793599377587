.account-list-divider {
    border-bottom: 1px solid palette(grey, 400) !important;
}

.account-list-card {
    cursor: pointer;
    background-color: #fff;
    box-shadow: shadows(1);
    transform: scale(1);
    transition: 0.2s !important;
    border: 2px solid palette(grey, 200) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover {
        box-shadow: shadows(3);
        transform: translate(0, -3px);
    }
    @media (min-width: breakpoints(lg)) {
        min-height: 337px !important;
    }
    @media (min-width: breakpoints(md)) {
        min-height: 337px !important;
    }
    .card-title {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        .account-name {
            @include typography(subtitle2);
            font-weight: 600 !important;
            padding: 10px 0px;
            display: flex;
            align-items: center;
        }
        .study-name {
            color: palette(grey, 600) !important;
            display: flex;
            justify-content: center;
        }
    }
    .account-summary {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 15px;
        .info-row {
            margin-bottom: 10px;
            .invoice-label {
                display: flex;
                justify-content: center;
            }
            .invoice-status-button {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }
        }
        .no-invoices-label {
            @include typography(subtitle2);
            color: palette(grey, 600) !important;
            font-weight: 600 !important;
            padding: 10px 0px;
            display: flex;
            align-items: center;
        }
    }
    overflow: visible !important;
}

.payment-detail {
    .pd-section-title {
        @include typography(body1);
        font-weight: 600 !important;
        margin-bottom: 10px;
    }
    .label-text {
        color: palette(grey, 600);
        &.payment-info {
            text-align: right;
        }
        &.invoice-info {
            min-width: 150px;
        }
    }
    .value-text {
        font-weight: 600 !important;
        &.check {
            font-size: 1.25rem !important;
        }
        &.payment-info {
            min-width: 125px;
            text-align: right;
        }
    }
    .payment-info-card {
        display: flex;
        min-height: 150px;
        .content-left {
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            justify-content: space-between;
            @media (min-width: breakpoints(sm)) {
                flex-direction: column;
                justify-content: space-evenly;
            }
        }
        .content-right {
            display: flex;
            flex-direction: column;
            @media (min-width: breakpoints(sm)) {
                align-items: flex-end;
                justify-content: space-evenly;
            }
            .content-row {
                display: flex;
                @media (max-width: breakpoints(sm)) {
                    justify-content: space-between;
                }
            }
        }
    }
    .invoice-info-card {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        min-height: 150px;
        .content-row {
            display: flex;
        }
    }
}

.tooltip-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    .tooltip-row-label {
        margin-right: 10px;
    }
}

.account-list-error {
    @extend .page-body;
    padding-right: 0px !important;
    padding-left: 0px !important;
    .paper {
        padding-top: 0px;
        height: 40vh !important;
    }
    .error-text {
        text-align: center !important;
        vertical-align: middle !important;
        height: 100% !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
}

.payments-footer {
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
    position: sticky;
    bottom: 0;
    z-index: 1002;

    &.constrainable {
        width: 100% !important;

        @media (min-width: breakpoints(md)) {
            &.collapsed {
                width: calc(100vw - 130px) !important;
            }
            &.expanded {
                width: calc(100vw - 300px) !important;
            }
        }
    }

    .table {
        min-width: max-content !important;
        max-width: 28% !important;

        &.constrainable {
            @media (max-width: breakpoints(sm)) {
                width: 100% !important;
                max-width: 100% !important;
            }

            @media (min-width: breakpoints(md)) {
                &.collapsed {
                    max-width: 26.5% !important;
                }
                &.expanded {
                    max-width: 28% !important;
                }
            }

            @media (min-width: breakpoints(xl)) {
                &.collapsed {
                    max-width: 24.5% !important;
                }
                &.expanded {
                    max-width: 25% !important;
                }
            }
        }

        .cell-header {
            @extend .primary-cell;
            @extend .text-align-left;
            background-color: palette(accent, blueBackground);
            color: palette(primary, dark) !important;
            width: 70% !important;
            font-weight: bold !important;
        }

        .cell-body {
            background-color: palette(accent, blueBackground);
            color: palette(primary, dark) !important;
            @extend .text-align-right;
            font-weight: bold;
        }
    }
}
