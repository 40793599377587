$primary-teal: $secondary-color;
$primary-blue: #0b3e91;
$secondary-blue: #3069b5;

$gradient: linear-gradient(90deg, #1c3e76 25%, #71a7dd 75%);
$background-gradient: linear-gradient(
    180deg,
    rgba(28, 62, 118, 1) 0%,
    rgba(113, 167, 221, 1) 50%,
    rgba(255, 255, 255, 0) 100%
);

.homepage {
    &.root {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
    }

    .center {
        display: flex;
        justify-content: center !important;
        align-items: center;
    }

    .animate-up {
        opacity: 0;
        transform: translateY(10vh);
        visibility: hidden;
        transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        -moz-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        -webkit-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        -o-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        &.is-visible {
            opacity: 1;
            transform: none;
            visibility: visible;
        }
    }

    .animate-right {
        opacity: 0;
        transform: translateX(-10vh);
        visibility: hidden;
        transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        -moz-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        -webkit-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        -o-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        &.is-visible {
            opacity: 1;
            transform: none;
            visibility: visible;
        }
    }

    .animate-left {
        opacity: 0;
        transform: translateX(10vh);
        visibility: hidden;
        transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        -moz-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        -webkit-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        -o-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        &.is-visible {
            opacity: 1;
            transform: none;
            visibility: visible;
        }
    }

    .demo-btn {
        min-height: 55px;
        min-width: 180px;
        margin-top: 25px;
        border-radius: 30px;
        background-color: $primary-teal;
        &.animate {
            opacity: 0;
            transform: translateX(-10vh);
            visibility: hidden;
            transition: opacity 0.6s ease-out 2.5s, transform 1.2s ease-out 2.5s;
            -moz-transition: opacity 0.6s ease-out 2.5s, transform 1.2s ease-out 2.5s;
            -webkit-transition: opacity 0.6s ease-out 2.5s, transform 1.2s ease-out 2.5s;
            -o-transition: opacity 0.6s ease-out 2.5s, transform 1.2s ease-out 2.5s;
            will-change: opacity, visibility;
            &.is-visible {
                opacity: 1;
                transform: none;
                visibility: visible;
            }
        }

        .btn-text {
            @include typography(button);
            font-size: 20px !important;
        }
    }

    .demo-btn:hover {
        background-color: $primary-teal;
    }

    .title-section {
        padding: 150px 0px 40px 100px;
        height: 100vh;
        background: url(../../media/homepage/hero/hero.png) no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        transition: justify-content 1s ease-in-out;
        @media (max-width: breakpoints(sm)) {
            padding: 70px 10px 40px 10px;
            justify-content: center;
            text-align: center;
        }

        @media (max-width: breakpoints(sm)) {
            // improve visibility of text on smaller screen
            // background: white;
            background: url(../../media/homepage/hero/hero-small.png) no-repeat center center;
        }

        .full-logo {
            display: flex;
            align-items: center;
            @media (max-width: breakpoints(sm)) {
                flex-wrap: wrap;
            }

            .logo-div {
                width: 100px;
                @media (max-width: breakpoints(sm)) {
                    width: 100%;
                }

                .logo {
                    max-width: 100px;
                }
            }

            .title {
                @include typography(h1);
                font-size: 100px !important;
                font-weight: 800 !important;
                letter-spacing: 0.2em !important;
                text-shadow: 1px 1px 2px rgba(0,0,0,0.14), 2px 2px 5px rgba(0,0,0,0.12);
                color: $primary-blue;
                @media (max-width: breakpoints(sm)) {
                    width: 100%;
                }
            }
        }

        .tag-line {
            @media (min-width: breakpoints(sm)) {
                max-width: 500px;
            }
            @include typography(subtitle1);
            font-size: 30px !important;
            line-height: 1.5 !important;
            font-weight: 400 !important;

            //animation
            opacity: 0;
            transform: translateX(-10vh);
            visibility: hidden;
            transition: opacity 0.6s ease-out 1.5s, transform 1.2s ease-out 1.5s;
            -moz-transition: opacity 0.6s ease-out 1.5s, transform 1.2s ease-out 1.5s;
            -webkit-transition: opacity 0.6s ease-out 1.5s, transform 1.2s ease-out 1.5s;
            -o-transition: opacity 0.6s ease-out 1.5s, transform 1.2s ease-out 1.5s;
            will-change: opacity, visibility;
            &.is-visible {
                opacity: 1;
                transform: none;
                visibility: visible;
            }
        }
    }

    .learn-more-btn {
        position: absolute;
        top: 90vh;
        z-index: 2;
        align-self: center;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        &.bounce {
            -moz-animation: bounce 3s infinite;
            -webkit-animation: bounce 3s infinite;
            animation: bounce 3s infinite;
        }
        @keyframes bounce {
            0%,
            20%,
            50%,
            80%,
            100% {
                transform: translateY(0);
            }
            40% {
                transform: translateY(-30px);
            }
            60% {
                transform: translateY(-15px);
            }
        }

        .text {
            @include typography(h6);
            font-weight: 700 !important;
            text-align: center;
        }

        .arrow {
            align-self: center;
            text-align: center;
        }
    }

    .overview-section {
        padding: 10% 0%;
        background: $background-gradient;
        display: flex;
        flex-direction: column;

        .craft-div {
            min-height: 475px;
            margin-bottom: 7%;
            display: flex;
            flex-wrap: wrap;
            @media (max-width: breakpoints(md)) {
                align-content: space-evenly;
            }

            .craft-title {
                padding: 0px 100px;
                width: 35%;
                @include typography(h5);
                font-size: 3rem !important;
                font-weight: 900 !important;
                color: white !important;
                @media (max-width: breakpoints(sm)) {
                    font-size: 2rem !important;
                }
                @media (max-width: breakpoints(md)) {
                    width: 100%;
                    padding: 0px 40px;
                    text-align: center;

                    .title-break {
                        display: none;
                    }
                }
            }

            .craft-summary-div {
                width: 65%;
                display: flex;
                flex-direction: column;
                align-self: flex-end;
                @media (max-width: breakpoints(md)) {
                    width: 100%;
                }
            }
        }

        .key-points-div {
            display: flex;
            flex-direction: column;
        }

        .header-text {
            // all headers (not title) in this section
            @include typography(h4);
            font-weight: 400 !important;
            color: white !important;
            margin-bottom: 10px;
            &.first {
                // craft overview header
                margin-left: 20px;
                text-align: left;
                @media (max-width: breakpoints(md)) {
                    margin: 40px 0px 0px 20px;
                    align-self: center;
                }
            }
            &.second {
                // key points header
                align-self: flex-end;
                margin-right: 50%;
                @media (max-width: breakpoints(md)) {
                    margin-right: 0%;
                    align-self: center;
                }
            }
        }

        .body-text {
            // all body text in this section
            @include typography(h6);
            font-weight: 400 !important;
            text-align: center;
            color: black !important;
            justify-self: center !important;
            justify-content: center;

            .color {
                color: $primary-color;
                @media (max-width: breakpoints(md)) {
                    color: white !important;
                }
            }
        }

        .bold {
            font-weight: 800 !important;
        }

        .box {
            background-color: white;
            min-height: 100px;
            width: 50%;
            padding: 20px 40px;
            margin: 20px 0px;
            @media (max-width: breakpoints(sm)) {
                width: 100%;
            }
            @media (max-width: breakpoints(md)) {
                width: 75%;
            }
            &.top {
                width: 100%;
                min-height: 200px;
                padding: 40px 80px;
                border-radius: 100px 0px 0px 100px;
                align-self: flex-end;
                margin: 0px;

                @media (max-width: breakpoints(md)) {
                    background-color: transparent;
                }
            }
            &.left {
                border-radius: 0px 100px 100px 0px;
                align-self: flex-start;
            }
            &.middle {
                border-radius: 100px;
                align-self: center;

                // custom animation
                opacity: 0;
                transform: translateX(-5vh);
                visibility: hidden;
                transition: opacity 0.6s ease-out, transform 1.2s ease-out;
                -moz-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
                -webkit-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
                -o-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
                will-change: opacity, visibility;
                &.is-visible {
                    opacity: 1;
                    transform: none;
                    visibility: visible;
                }
            }
            &.right {
                border-radius: 100px 0px 0px 100px;
                align-self: flex-end;
            }
        }
    }

    .feature-section {
        padding: 7% 5%;
        display: flex;
        flex-direction: column;

        .title {
            margin-bottom: 60px;
            @include typography(h2);
            font-weight: 800 !important;
            color: $primary-color;
            align-self: center;
            @media (max-width: breakpoints(sm)) {
                @include typography(h4);
                font-weight: 800 !important;
            }
            @media (max-width: breakpoints(md)) {
                @include typography(h3);
                font-weight: 800 !important;
            }
        }

        .grid-item {
            display: flex;
            flex-direction: column;

            .card {
                width: 80% !important;
                height: 350px !important;
                display: flex;
                flex-direction: column;
                align-self: center;
                position: relative;
                border-radius: 20px;
                @media (max-width: breakpoints(md)) {
                    width: 85% !important;
                    height: 400px !important;

                    .feat-title {
                        @include typography(h5);
                        font-weight: 800 !important;
                    }

                    .feat-desc {
                        @include typography(body1);
                        font-size: 1.1rem !important;
                    }
                }

                .feat-img {
                    height: 60%;
                }

                .feat-title {
                    @include typography(h4);
                    font-weight: 800 !important;
                    text-align: left;
                    align-self: flex-end;
                    position: absolute;
                    left: 0px;
                    bottom: 43%;
                    color: white !important;
                    text-shadow: 5px black;
                    padding-left: 5%;
                }

                .feat-desc {
                    @include typography(h6);
                    text-align: center;
                }
            }
        }
    }

    .action-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10%;
        @media (max-width: breakpoints(sm)) {
            margin: 150px 40px;
        }

        .title {
            @include typography(h1);
            font-weight: 700 !important;
            width: 75%;
            align-self: flex-start;
            color: $primary-blue;
            @media (max-width: breakpoints(md)) {
                width: 100%;
            }
        }

        .text-and-logo-div {
            margin-top: 25px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            @media (max-width: breakpoints(md)) {
                justify-content: center;
            }

            .text-div {
                display: flex;
                flex-direction: column;
                width: 60%;
                @media (max-width: breakpoints(md)) {
                    width: 100%;
                    text-align: center;
                }

                .text {
                    font-size: 25px !important;
                    margin-bottom: 20px;
                }
            }

            .logo-div {
                width: 30%;
                max-width: 350px;
                min-width: 200px;
                display: flex;
                justify-content: center;
                @media (max-width: breakpoints(md)) {
                    width: 100%;
                }

                .logo {
                    max-width: 100%;
                    @media (max-width: breakpoints(md)) {
                        max-width: 50%;
                    }
                }
            }
        }

        .action-text {
            width: 750px;
            margin: 25px 0px;
            @include typography(h3);
            font-weight: 700 !important;
            color: $secondary-blue;
            text-align: center;
            @media (max-width: breakpoints(md)) {
                width: 450px;
            }
        }
    }

    .background-section {
        background: $gradient;
        padding: 40px;
        margin-bottom: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .img-div {
            width: 200px;
            margin-right: 40px;
            @media (max-width: breakpoints(md)) {
                margin-right: 0px;
                width: 100%;
            }

            .img {
                max-width: 100%;
            }
        }

        .text-div {
            width: 600px;
            min-height: 250px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media (max-width: breakpoints(md)) {
                text-align: center;

                .text {
                    padding-top: 40px;
                }
            }

            .text {
                color: white;
                @include typography(h6);
            }
        }
    }
}
