.table-header {
    background-color: palette(primary, main) !important;
    .primary-cell {
        @include typography(body1);
        color: #fff !important;
    }
    .selected {
        font-weight: 800 !important;
    }
    .header-cell-label {
        display: flex;
        align-items: center;
        .MuiSvgIcon-root {
            margin-right: -5px;
        }
    }
}
.horizontal-lines {
    .body-cell {
        box-shadow: none !important;
        border-top: 0.75px solid palette(grey, 200) !important;
        border-bottom: 0.75px solid palette(grey, 200) !important;
    }
}

.body-cell {
    box-shadow: 0 0 0 0.75px palette(grey, 200);
}
.disabled-cell {
    background-color: palette(grey, 100) !important;
    box-shadow: 0 0 0 0.75px palette(grey, A100);
}
.MuiTableBody-root .MuiSvgIcon-root {
    font-size: 1.3rem !important;
}
.paging-arrows {
    .MuiTablePagination-input {
        visibility: hidden;
    }
    .MuiTablePagination-caption {
        visibility: hidden;
    }
}

.info.MuiSvgIcon-root {
    color: palette(grey, 500);
    font-size: large;
    margin: 0px 10px;
}

.MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.5) !important;
}

.double-line-text {
    .title {
        @include typography(body1);
        font-weight: 600 !important;
        color: palette(primary, main);
    }
    .subtitle {
        color: palette(grey, 600);
    }
}

.MuiTableRow-root {
    &.clickable {
        &:hover {
            cursor: pointer;
            background-color: palette(grey, 100);
        }
    }
}

.table-pagination {
    overflow: hidden !important;
    .MuiToolbar-root {
        padding: 0px 0px 0px 5px !important;
        min-height: 0px !important;
        height: 30px !important;
        margin-bottom: 10px;
    }
    .MuiIconButton-root {
        padding: 3px;
    }
}

.border-separate {
    border-collapse: separate !important;
}

.constrainable {
    width: 100% !important;
    @media (min-width: breakpoints(md)) {
        &.collapsed {
            width: calc(100vw - 110px) !important;
        }
        &.expanded {
            width: calc(100vw - 280px) !important;
        }
    }
    form {
        max-width: none !important;
    }
}
.constrain-table {
    max-height: calc(100vh - 210px) !important;
    width: calc(100vw - 80px) !important;
}
.constrain-totals {
    width: calc(100vw - 120px) !important;
}
@media (min-width: breakpoints(md)) {
    .constrain-table {
        max-height: calc(100vh - 210px) !important;
        width: calc(100vw - 130px) !important;
    }
    .constrain-totals {
        width: calc(100vw - 140px) !important;
    }
    .constrain-expanded {
        .constrain-table {
            max-height: calc(100vh - 210px) !important;
            width: calc(100vw - 300px) !important;
        }
        .constrain-totals {
            width: calc(100vw - 335px) !important;
        }
    }
}
