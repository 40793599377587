// Handles special heights due to an environment header
.env {
    .page-heading {
        top: 80px !important;
    }
    .side-bar {
        .MuiList-root {
            top: 80px !important;
            .toggle {
                top: 80px !important;
            }
        }
    }
    .header-drawer {
        .MuiBackdrop-root {
            top: 80px !important;
        }
        .MuiDrawer-paperAnchorTop {
            top: 80px !important;
        }
    }
}
// Wraps the page content and any access/navigational panels
.page {
    @media (min-width: breakpoints(md)) {
        display: flex;
        width: 100%;
    }
    @media (max-width: (breakpoints(md) - 1)) {
        display: block;
    }
}

.page-narrow {
    @media (max-width: breakpoints(xl)) {
        display: flex;
        width: 100%;
    }
    @media (max-width: (breakpoints(sm) - 1)) {
        display: block;
    }
}
// Wraps the page heading and page body
.page-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    &.inner {
        width: 100%;
        min-width: calc(100% - 250px);
    }
}
// Style of the subheading horizontal bar
.page-heading {
    position: sticky;
    top: 45px;
    z-index: 1180;
    height: 45px;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    box-shadow: shadows(1);
    .title {
        @include typography(subtitle1);
        &.inactive {
            color: palette(text, hint) !important;
        }
    }
    .arrow {
        margin: 0px 5px 0px 5px;
        color: palette(text, hint) !important;
    }
    @media (max-width: breakpoints(sm)) {
        height: auto;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}
// Contains the main features of the page
.page-body {
    padding: 20px;
    &.max-lg {
        max-width: breakpoints(lg);
        margin: auto !important;
    }
    &.max-lgxl {
        max-width: calc(calc(#{breakpoints(lg)} + #{breakpoints(xl)}) / 2);
        margin: auto !important;
    }
    &.max-xl {
        max-width: breakpoints(xl);
        margin: auto !important;
    }
    &.page-heading-spacer {
        margin-top: 40px;
    }
    .feature-tabs {
        margin-top: -10px !important;
        margin-bottom: 20px !important;
    }
    &.no-ma {
        margin: 0px !important;
    }
}
// A title for the page body
.page-title {
    @include typography(h5);
    font-weight: 600 !important;
    padding-bottom: 0.5rem;
}

// Fills the screen while centering the child node
.full-screen {
    // ideally max of 100% and this value
    height: calc(100vh - #{$header-height});
    width: 100%;
    &.centered {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
