.env-header {
    background-color: palette(grey, 100);
    height: 35px !important;
    display: flex;
    .header-text {
        width: 100%;
        text-align: center;
        @include typography(h4);
        font-weight: 600 !important;
        color: palette(error, dark);
        margin: auto !important;
    }
}
