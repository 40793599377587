.info-icon {
    color: palette(grey, 500) !important;
    margin-left: 6px;
    height: 20px !important;
}

.study-statistics-table {
    .cell {
        border-bottom-style: solid;
        border-bottom-color: palette(background, default);
        border-bottom-width: 0.15rem;

        border-right-style: solid;
        border-right-color: palette(background, default);
        border-right-width: 0.15rem;
    }

    .header-cell {
        @extend .cell;

        &.indent {
            padding-left: 36px;
        }

        &.activity {
            background-color: palette(accent, greenBackground);
            color: palette(accent, greenText);
        }

        &.cost {
            background-color: palette(accent, yellowBackground);
            color: palette(accent, yellowText);
        }

        &.productivity {
            background-color: palette(accent, blueBackground);
            color: palette(accent, blueText);
        }

        &.effort {
            background-color: palette(accent, purpleBackground);
            color: palette(accent, purpleText);
        }

        button {
            padding: 0;
            justify-content: start;
            width: 100%;
            height: 100%;
            
            span {
                padding-left: 36px;
            }
        }
    }

    .user-header-cell {
        background-color: palette(background, default);
        padding: 0;

        button {
            justify-content: space-between;
            width: 100%;
        }
    }

    .user-header-cell[data-open="false"] {
        @extend .user-header-cell;
        border-bottom-style: solid;
        border-bottom-color: palette(background, paper);
        border-bottom-width: 0.15rem;
    }

    .data-cell {
        @extend .cell;

        &.unused {
            background-color: palette(background, default) !important;
            background-image: repeating-linear-gradient(
                -45deg,
                palette(background, paper),
                palette(background, paper) 5%,
                palette(background, default) 5%,
                palette(background, default) 10%
            ) !important;
        }
    }

    .data-cell[data-disabled=true] {
        @extend .data-cell;
        color: rgba(0, 0, 0, 0.26) !important;
    }
    
    .data-cell[data-negative=true] {
        @extend .data-cell;
        color: palette(error, main) !important;
    }

    .top-left {
        border-radius: 3px 0 0 0;
    }

    .sticky {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
    }

    .resource-row-hover {
        &:hover {
           background-color: xyz;
        }
     }
}