$primary-teal: $secondary-color;
$primary-blue: #0B3E91;
$secondary-blue: #3069B5;

.homepage-header{
    overflow: hidden !important;

    .app-bar{
        transition: background 1s ease-in-out;
        &.transparent-bg{
            background: transparent;
        }
        &.solid-bg{
            background: white;
        }
    }
    .top-right{
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;        
        .btn-color{
            color: $primary-blue !important;
            &.outline-color{
                box-shadow: inset 0px 0px 0px 2px $primary-blue !important;
            }
        }
    }
    .full-logo-div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none !important;
        transition: opacity 1s ease-in-out;
        cursor: pointer;
        &.hide-logo{
            opacity: 0%;
            cursor: default;
        }
        .logo-div {
            width: 30px;
            margin-right: 5px;
            .logo {
                max-width: 100%;
            }
        }
        .text-logo {
            font-size: 22px;
            font-weight: 800 !important;
            letter-spacing: 0.2em !important;
            color: $primary-blue;
        }
    }
}