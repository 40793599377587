.tab-header {
    // TO BE DELETED AFTER REFACTORING TO USE MUI TABS
    border-style: solid;
    border-width: 0 0 0.05em 0;
    border-color: palette(text, hint);
}
.tab-item {
    // TO BE DELETED AFTER REFACTORING TO USE MUI TABS
    @include typography(subtitle1);
    color: palette(primary, main) !important;
    background-color: palette(background, default) !important;
    box-shadow: none !important;
    border-style: solid !important;
    border-width: 0 0 0.25em 0 !important;
    border-radius: 0 !important;
    margin-right: 10px !important;
}
.not-selected-tab-item {
    // TO BE DELETED AFTER REFACTORING TO USE MUI TABS
    @extend .tab-item;
    border-style: hidden !important;
}
.selected-tab-item {
    // TO BE DELETED AFTER REFACTORING TO USE MUI TABS
    @extend .tab-item;
    border-color: palette(primary, main) !important;
}

.soe-table-header {
    .soe-primary-cell {
        @include typography(body1);
        @extend .soe-grid-top-sticky;
        background-color: palette(primary, main) !important;
        border-top: 1.5px palette(primary, dark);
        border-right: 1.5px solid palette(primary, dark);
        width: 100px !important;
        min-width: 100px !important;
        vertical-align: bottom !important;
    }
    .soe-header-cell-label {
        display: flex;
        color: #fff !important;
    }
    .soe-header-info {
        min-width: 50px !important;
        width: 50px !important;
        vertical-align: bottom !important;
    }
    .soe-header-procedure {
        @extend .soe-grid-top-sticky;
        min-width: 300px !important;
        width: 300px !important;
        vertical-align: bottom !important;
        left: 0px !important;
        z-index: 100;
        background-color: #fff;
        position: -webkit-sticky;
        position: sticky;
    }
    .soe-header-resource {
        @extend .soe-header-info;
        left: 300px !important;
        top: 0px !important;
        position: sticky;
        z-index: 100;
        background-color: #fff;
    }
    .soe-header-time-required {
        @extend .soe-header-info;
        left: 370px !important;
        top: 0px !important;
        position: sticky;
        z-index: 100;
        background-color: #fff;
    }
    .soe-header-actual-cost {
        min-width: 90px !important;
        width: 90px !important;
        vertical-align: bottom !important;
        left: 420px !important;
        top: 0px !important;
        position: sticky;
        z-index: 100;
        background-color: #fff;
        border-right: 1.5px solid palette(primary, dark);
    }
    .soe-header-blank {
        @extend .soe-grid-top-sticky;
        min-width: 300px !important;
        width: 300px !important;
        vertical-align: bottom !important;
        left: 0px !important;
        z-index: 100;
        background-color: #fff;
        border-right: 1.5px solid palette(primary, dark);
    }
}
.soe-grid-selected {
    border-top: 1.5px solid palette(grey, 400) !important;
    border-right: 1.5px solid palette(grey, 400) !important;
}
.soe-grid-unselected {
    background-color: palette(grey, 300) !important;
}
.soe-grid-unselected-editing {
    @extend .soe-grid-unselected;
}
.soe-grid-unselected-editing:hover {
    background-color: palette(grey, 600) !important;
    cursor: pointer;
}
.soe-procedure-label-cell {
    background-color: palette(primary, main) !important;
    color: #fff !important;
    border-top: 1.5px solid palette(primary, dark) !important;
    left: 0px !important;
    position: sticky !important;
    width: 269px !important;
}
.soe-procedure-edit-cell {
    background-color: palette(primary, main) !important;
    color: #fff !important;
    border-top: 1.5px solid palette(primary, dark) !important;
    left: 269px !important;
    position: sticky !important;
    .MuiIconButton-root {
        padding: 0px;
    }
}
.soe-resource-cell {
    @extend .soe-grid-readonly-cell;
    left: 300px !important;
    position: sticky !important;
    background-color: #fff;
}
.soe-time-required-cell {
    @extend .soe-grid-readonly-cell;
    left: 370px !important;
    position: sticky !important;
    background-color: #fff;
}
.soe-actual-cost-cell {
    @extend .soe-grid-readonly-cell;
    left: 420px !important;
    position: sticky !important;
    background-color: #fff;
}
.soe-grid-cell:hover {
    background-color: palette(grey, 600) !important;
    cursor: pointer;
}
.soe-grid-cell {
    border-top: 1.5px solid palette(grey, 400) !important;
    border-right: 1.5px solid palette(grey, 400) !important;
}
.soe-body-cell {
    border-top: 1.5px solid palette(grey, 400) !important;
    border-right: 1.5px solid palette(grey, 400) !important;
}
.soe-grid-readonly-cell {
    border-top: 1.5px solid palette(grey, 400) !important;
    border-right: 1.5px solid palette(grey, 400) !important;
}
.soe-grid-added {
    background-color: #92a0d2 !important;
}
.soe-grid-removed {
    background-color: palette(secondary, light) !important;
}
.current-final-cost {
    font-weight: 800 !important;
    color: palette(primary, main) !important;
}
.low-value-alert {
    color: #efa728;
}

.soe-grid-no-top-padding {
    margin-top: 10px;
    padding: 10px;
}

.totals-grid-header-total {
    position: sticky !important;
    left: 370px !important;
    background-color: #fff;
    min-width: 140px !important;
    width: 140px !important;    
    vertical-align: bottom !important;
}
.soe-ptc-border {
    border-bottom: 1.5px solid palette(primary, dark) !important;
}
.soe-sum-totals {
    @extend .soe-grid-readonly-cell;
    position: sticky !important;
    left: 370px !important;
    background-color: #fff;

    min-width: 140px !important;
    width: 140px !important;
}
.totals-grid-sv-header {
    @include typography(body1);
    width: 100px !important;
    min-width: 100px !important;
    vertical-align: bottom !important;
}
.totals-grid-section-starter {
    border-left: 1.5px solid palette(primary, dark) !important;
    border-top: 1.5px solid palette(primary, dark) !important;
    border-right: 1.5px solid palette(primary, dark) !important;
    background-color: #fff;
    left: 0px !important;
    position: sticky !important;

    min-width: 300px !important;
    width: 300px !important;
}
.empty-soe-grid {
    padding-right: 0px !important;
    padding-left: 0px !important;
    height: 40vh !important;
    .MuiTypography-root {
        text-align: center !important;
        vertical-align: middle !important;
        height: 80% !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
}

.soe-grid-top-sticky {
    top: 0px !important;
    position: sticky !important;
}

.employee-cost-button {
    min-width: auto !important;
    .MuiButton-label {
        width: max-content;
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }
}

.employee-costs {
    width: 100%;
    .MuiTable-root {
        background-color: #fff;
        box-shadow: 0px 0px 0px 1px palette(grey, 400) inset !important;
    }
    .MuiTableRow-root {
        .MuiTableCell-body {
            border: 1px solid palette(grey, 400) !important;
        }
        .MuiTableCell-body:first-child {
            border: 1px solid palette(primary, dark) !important;
            font-weight: 700;
            background-color: palette(primary, main) !important;
            color: #fff !important;
            @media (min-width: breakpoints(lg)) {
                width: 300px !important;
            }
        }
    }
    .edit-cell {
        width: 50px !important;
    }
}

.pe-service-provider {
    .section-text-div {
        margin-left: 20px;
        position: absolute;
        bottom: 120px;
        background-color: white;
        .section-text {
            font-size: smaller;
            color: palette(gray, 700);
        }
    }
    .autocomplete-div {
        background-color: white;
        border-color: palette(gray, 400);
        border-style: solid;
        border-width: thin;
        padding: 10px;
        border-radius: 4px;
        margin: 0px 10px;
    }
}

.soe-unsaved-changes {
    justify-content: center;
    @include typography(body1);
    color: palette(error, main);
    text-align: center;
    font-weight: 600 !important;
}

.contract-visit-modal {
    .subtitle {
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
    }
    .label {
        display: flex;
        justify-content: flex-end;
        @include typography(body1);
        color: palette(grey, 700);
        font-weight: 600 !important;
        padding: 5px 0px;
    }
    .cost-value {
        display: flex;
        justify-content: flex-end;
        @include typography(body1);
        padding: 5px 0px;
    }
}
