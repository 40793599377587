.contact-page {

    .content {
        height: 50vh;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: url(../../../media/homepage/contact/banner.png) no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .animate-right {
        opacity: 0;
        transform: translateX(-5vh);
        visibility: hidden;
        transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        -moz-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        -webkit-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        -o-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        will-change: opacity, visibility;

        &.is-visible{
            opacity: 1;
            transform: none;
            visibility: visible;
        }
    }

    .animate-left {
        opacity: 0;
        transform: translateX(5vh);
        visibility: hidden;
        transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        -moz-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        -webkit-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
        will-change: opacity, visibility;

        &.is-visible{
            opacity: 1;
            transform: none;
            visibility: visible;
        } 
    }

    .animate-up {
        opacity: 0;
        transform: translateY(5vh);
        visibility: hidden;
        transition: opacity 0.6s ease-out .6s, transform 1.2s ease-out .6s;
        -moz-transition: opacity 0.6s ease-out .6s, transform 1.2s ease-out .6s;
        -webkit-transition: opacity 0.6s ease-out .6s, transform 1.2s ease-out .6s;
        -o-transition: opacity 0.6s ease-out .6s, transform 1.2s ease-out .6s;

        &.is-visible{
            opacity: 1;
            transform: none;
            visibility: visible;
        }
    }

    .banner-text {
        text-align: center;

        .title {
            @include typography(h1);
            font-weight: 600 !important;
            margin: 5vh;
        }

        .tag-line {
            @include typography(h4);
            margin: 5vh;   
        }
    }

    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        position: absolute;
        top: 35vh;
        width: 90vw;

        .card {
            margin: 2vw;
            min-height: 45vh;
            padding: 20px;

            @media (min-width: breakpoints(md)) {
                width: 40vw;
            }
            @media (max-width: breakpoints(md)) {
                width: 75vw;
            }
            @media (max-width: breakpoints(sm)) {
                width: 100vw;
            }

            .card-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                justify-items: center;

                .card-title {
                    @include typography(h4);
                    margin-bottom: 5%;
                }

                .card-text{
                    @include typography(h2);
                    font-size: 18px !important;
                    font-weight: 500 !important;
                    margin-bottom: 20px !important;
                }

                .contact-info-div{
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    margin-top: 5%;

                    .type-column{
                        display: flex;
                        flex-direction: column;
                        justify-content: left;
                        margin-right: 20px;
                        align-self: flex-start;
                    }

                    .text-column{
                        display: flex;
                        flex-direction: column;
                        justify-content: left;
                        align-self: flex-start;
                    }
                }  

                .form-div{
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;

                    .btn-div{
                        display: flex;
                        justify-content: flex-end;
                        width: 100%;
                        padding: 0% 2%;

                        .btn{
                            width: 25%;
                        }
                    }
                }
            }
        }
    }
}


