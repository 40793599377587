.permission-field {
    > div {
        padding: 0px;
    }
    &.label {
        font-weight: 700;
        width: 60%;
        padding: 1.25em;
        margin-top: 8px !important;
        margin-bottom: 4px !important;
        border-radius: 3px 0 0 3px;
        background-color: palette(primary, main);
        color: #fff;
        min-height: 3.6em;
    }
    .MuiInputBase-root {
        box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.05);
        border-radius: 0 3px 3px 0 !important;
        min-height: 3.6em;
    }
}
