.documents-height {
    overflow-y: auto;
    &.list {
        min-height: 500px !important;
        max-height: calc(100vh - 220px) !important;
    }
    &.directory-preview {
        max-height: calc(100vh - 288.75px) !important;
    }
    &.document-preview {
        max-height: calc(100vh - 248.75px) !important;
    }
}

.directory-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0px 10px !important;
    .text {
        @include typography(subtitle2);
        font-weight: 600 !important;
    }
}

.upload-button {
    color: white !important;
    background-color: palette(primary, main) !important;
    box-shadow: 0px 0px 0px 1.5px darken(palette(primary, main), 15%) inset !important;
    width: 100% !important;
    height: 45px !important;
}

.upload-button-disabled {
    color: #858585 !important;
    background-color: palette(grey, 300) !important;
    box-shadow: 0px 0px 0px 1.5px darken(palette(grey, 400), 15%) inset !important;
    width: 100% !important;
    height: 45px !important;
}

.documents-preview {
    .card-content {
        margin: 10px;
        background-color: palette(grey, 50);
        overflow-x: auto;
        overflow-y: auto;
    }
    .unavailable-message {
        background-color: white;
        font-size: 1.3em;
    }
    .uploaded-date {
        display: inline-block;
        box-shadow: 0px 0px 0px 1.5px darken(palette(primary, main), 15%) inset !important;
        padding: 2px 15px 2px 10px !important;
    }
    .document-buttons-disabled {
        display: flex;
        justify-content: flex-end;
        .MuiButton-root {
            color: #858585 !important;
            background-color: palette(grey, 300) !important;
            box-shadow: 0px 0px 0px 1.5px darken(palette(grey, 400), 15%) inset !important;
            margin: 3px;
            padding: 2px 15px 2px 10px !important;
            min-width: 45px;
            .MuiSvgIcon-root {
                margin-right: 5px;
            }
            @media (max-width: breakpoints(lg)) {
                margin: 3px;
                padding: 2px 5px !important;
                div {
                    display: none;
                }
                .MuiSvgIcon-root {
                    margin: 0px;
                }
            }
        }
    }
    .document-delete-button {
        display: flex;
        justify-content: flex-end;
        .MuiButton-root {
            color: white !important;
            background-color: palette(error, main) !important;
            box-shadow: 0px 0px 0px 1.5px darken(palette(primary, main), 15%) inset !important;
            margin: 3px;
            padding: 2px 15px 2px 10px !important;
            min-width: 45px;
            .MuiSvgIcon-root {
                margin-right: 5px;
            }
            @media (max-width: breakpoints(lg)) {
                margin: 3px;
                padding: 2px 5px !important;
                div {
                    display: none;
                }
                .MuiSvgIcon-root {
                    margin: 0px;
                }
            }
        }
    }
}

.split-cards {
    height: 100%;

    &.directory {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        .card-header {
            border-right: none !important;
        }
    }
    &.details {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        position: relative;
        &:after {
            content: "";
            width: 2px;
            height: 700px;
            background: palette(grey, 300);
            position: absolute;
            left: 0px;
            top: 45px;
        }
        .card-header {
            justify-content: flex-start;
            .MuiSvgIcon-root {
                font-size: medium;
                margin-bottom: 3px;
            }
            .active-path {
                font-weight: 800;
            }
        }
    }
    .card-header {
        height: 45px;
        border: 2px solid palette(primary, dark) !important;
        background-color: palette(primary, main);
        color: white;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .MuiButtonBase-root {
            padding: 0px !important;
        }
    }
    .confirm-delete {
        background-color: palette(error, main) !important;
        color: #fff !important;
    }
    .MuiInputLabel-outlined {
        transform: translate(14px, 12px) scale(1) !important;
        &.MuiInputLabel-shrink {
            transform: translate(14px, -6px) scale(0.75) !important;
        }
    }
    .MuiOutlinedInput-input {
        padding: 10.5px 14px !important;
    }
    .MuiList-root {
        padding: 0px;
        .MuiListItem-root {
            @include typography(body1);
            border-top: 1px solid palette(grey, 200) !important;
            border-bottom: 1px solid palette(grey, 200) !important;
            cursor: pointer;
            padding: 12px 15px;
            &:hover {
                background-color: palette(grey, 100) !important;
            }
            &.active {
                background-color: palette(grey, 100) !important;
                font-weight: 700 !important;
            }
        }
    }
}

.file-actions {
    margin: 3px;
    width: 100%;
    display: flex;
    .MuiButton-root {
        padding: 0px 8px;
        margin: 2px 4px;
        .MuiSvgIcon-root {
            height: 18px;
            color: inherit;
        }
    }
}

.small-grey-button {
    color: palette(grey, 700) !important;
    background-color: palette(grey, 50) !important;
    font-size: small !important;
    padding-left: 4px !important;
    border: 1px solid palette(grey, A100) !important;
}

.small-grey-button-disabled {
    color: #858585 !important;
    background-color: palette(grey, 300) !important;
    font-size: small !important;
    padding-left: 4px !important;
    border: 1px solid palette(grey, A100) !important;
}

.folder-contents-table {
    .MuiTable-root {
        border-radius: 0px !important;
    }
    .MuiTableHead-root {
        background-color: palette(grey, 100) !important;
        border-top: 2px solid palette(grey, 300) !important;
        border-bottom: 2px solid palette(grey, 300) !important;
        .MuiTableCell-root {
            color: palette(grey, 700) !important;
            font-weight: 600 !important;
        }
    }
    .body-cell-label {
        margin-bottom: -3px;
    }
}

.icon-cell-header {
    padding-left: 70px !important;
}
.icon-cell {
    .file-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        .MuiSvgIcon-root {
            margin-left: 16px;
            margin-right: 24px;
        }
    }
}

.clickable-table-row {
    &:hover {
        cursor: pointer;
        background-color: palette(grey, 200);
    }
}

.new-directory-item {
    z-index: 1301 !important;
    .MuiMenuItem-root {
        color: palette(grey, 700);
    }
    .MuiSvgIcon-root {
        font-size: large;
        margin-right: 5px;
    }
}

.plain-message {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column;
    height: 100%;
}

.document-tree-label {
    display: flex !important;
    align-items: center !important;
    flex-direction: row;
    .MuiSvgIcon-root {
        font-size: medium;
        margin-bottom: 4px;
        margin-right: 5px;
        color: palette(primary, dark);
    }
}
