.outlined-file-input{

    &.error{
        .MuiFormLabel-root{
            color: palette(error, main) !important;
        }
        .MuiFormHelperText-root{
            color: palette(error, main) !important;
        }
    }
   
}

.file-upload-contents{
    display: flex;
    flex-direction: column;
    min-width: max-content !important;

    .selected-file-text{
        padding-top: 5px;
        display: flex;
        flex-direction: row;

        .remove-file{
          font-size: .9em !important;  
        }
    }
}