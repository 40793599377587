.simulator-productivity-chart {
    $employee-color: palette(grey, 700);
    $patient-color: palette(grey, 400);

    .productivity-icon {
        fill: palette(success, main);
    }
    .employee-icon {
        color: $employee-color !important;
    }
    .patient-icon {
        color: $patient-color !important;
    }

    .legend {
        margin-left: 70px;

        div {
            span {
                margin-top: 3px;
            }
        }
        div:nth-child(n+2) {
            margin-left: 6px;
        }
    }

    .line-positive {
        stop-color: palette(success, main);
    }
    .line-negative {
        stop-color: palette(error, main);
    }
    .line-background {
        stop-color: palette(background, paper);
    }

    .employee-reference-line {
        line {
            stroke: $employee-color !important;
        }
    }
    .patient-reference-line {
        line {
            stroke: $patient-color !important;
        }
    }

    .year-controls {
        @extend .aligned-row;

        span:nth-child(1) {
            @extend .left;
            button {
                transform: translate(70px, -36px);
            }
        }
        span:nth-child(2) {
            @extend .right;
            button {
                transform: translate(-20px, -36px);
            }
        }

        button {
            background-color: palette(primary, main);
            color: palette(primary, contrastText)
        }
        button:hover {
            background-color: palette(primary, dark);
        }
    }
}

.productivity-point-tooltip {
    @extend .column;
    @extend .tip-padding;

    div {
        @extend .aligned-row;

        span:nth-child(2) {
            @extend .right;
            margin-Left: 1rem;
        }
    }
}

.productivity-reference-line-tooltip {
    @extend .column;
    @extend .tip-padding;
}

.resource-legend {
    margin-left: 70px;

    div {
        span {
            margin-top: 3px;
        }
    }
    div:nth-child(n+2) {
        margin-left: 6px;
    }
}