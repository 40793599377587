.input-table {
    .MuiFormControl-marginDense {
        margin: 0px !important;
    }
    label + .MuiInput-formControl {
        margin: 0px !important;
    }
}

.service-provider-input {
    margin: 8px 0px 4px;
}