.study-overview {
    padding-top: 10px !important;

    .MuiCard-root {
        padding: 15px;
        width: 100%;
        background-color: palette(grey, 50);
    }

    .section-heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 50px;
        .text {
            @include typography(body1);
            font-weight: 600 !important;
        }
        .MuiSvgIcon-root {
            color: palette(grey, 500);
            font-size: large;
            margin: 0px 10px;
        }
        .MuiButton-label {
            font-weight: 800;
            color: palette(secondary, main);
        }
    }

    .overview-card-div {
        position: relative !important;
        min-width: 100% !important;
    }

    .info {
        .MuiTypography-root {
            font-weight: 600 !important;
            padding-bottom: 10px !important;
        }
        .MuiTableCell-head {
            height: 32px !important;
            font-weight: 600;
            color: palette(grey, A600);
        }
        .MuiButton-root {
            height: 25px !important;
        }
        &.overview-card {
            overflow: hidden;
        }
        .costs-table {
            margin-top: 10px;
            .MuiTable-root {
                border-radius: shape(borderRadius) !important;
                background-color: #fff;
                box-shadow: 0px 0px 0px 2px palette(grey, 200) inset !important;
            }
            .MuiTableRow-root {
                outline: 1px solid palette(grey, 200) !important;
                .MuiTableCell-head {
                    color: palette(grey, A600);
                }
                .MuiTableCell-head:first-child {
                    border-right: 2px solid palette(grey, 200) !important;
                    font-weight: 700;
                }
            }
            .MuiTableCell-head {
                height: 35px !important;
            }
        }
    }

    .clickable-text {
        color: palette(primary, main);
        cursor: pointer;
        font-weight: bold;
        text-decoration: underline;
    }
}
.start-study-warning {
    color: palette(error, main);
}
.study-status {
    position: absolute;
    top: -12px;
    right: 20px;
    z-index: 100;
    &.not-started {
        background-color: palette(accent, redBackground);
        color: palette(accent, redText);
    }
    &.started {
        background-color: palette(accent, greenBackground);
        color: palette(accent, greenText);
    }
    &.completed {
        background-color: palette(accent, blueBackground);
        color: palette(accent, blueText);
    }
}

.study-details {
    padding: 0px !important;
    .MuiGrid-item:first-child {
        border-right: 1.5px solid palette(grey, 300);
        @media (max-width: breakpoints(sm)) {
            border-bottom: 1.5px solid palette(grey, 300);
            border-right: none;
        }
    }
    .MuiTableContainer-root {
        padding: 15px !important;
        .MuiTableRow-root {
            .MuiTableCell-root:first-child {
                width: 202px !important;
                text-align: end !important;
            }
            .MuiTableCell-root:nth-child(2) {
                padding-left: 10px;
            }
        }
    }
}

.detail-row-text-details {
    .MuiTableRow-root {
        .MuiTableCell-root:first-child {
            color: palette(grey, A600) !important;
            font-weight: 500 !important;
        }
        .MuiTableCell-root:nth-child(2) {
            color: palette(primary, main) !important;
            font-weight: 600 !important;
        }
    }
}

.detail-row-text-identification {
    .MuiTableRow-root {
        .MuiTableCell-root:first-child {
            color: palette(grey, A600) !important;
            font-weight: 500 !important;
            width: 150px;
        }
        .MuiTableCell-root:nth-child(2) {
            color: palette(primary, main) !important;
            font-weight: 600 !important;
            width: 150px;
        }
    }
}
