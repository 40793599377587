@import "../Utils";

.aligned-row {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    .left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
    }
    .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
    }
    &.persist {
        @media (max-width: breakpoints(sm)) {
            flex-direction: column;
            .left {
                width: 100%;
                justify-content: center !important;
                align-items: center !important;
            }
            .right {
                width: 100%;
                justify-content: center !important;
                align-items: center !important;
            }
        }
    }
    @media (max-width: breakpoints(sm)) {
        flex-direction: column;
        .left {
            width: 100%;
            justify-content: center !important;
            align-items: center !important;
        }
        .right {
            width: 100%;
            justify-content: center !important;
            align-items: center !important;
        }
    }
}

.primary-color {
    background-color: palette(primary, main) !important;
    color: palette(primary, contrastText) !important;
}
.background-color {
    background-color: palette(background, default) !important;
}
.background-color-paper {
    background-color: palette(background, paper) !important;
}
.secondary-color {
    background-color: palette(secondary, main) !important;
}
.secondary-button {
    background-color: palette(secondary, main) !important;
    color: palette(secondary, contrastText) !important;
}
.delete-color {
    background-color: palette(error, main) !important;
    color: #fff !important;
}
.transparent-button {
    background-color: transparent !important;
    border: 1.5px solid palette(primary, main) !important;
    color: palette(primary, main) !important;
}
.success-color {
    color: palette(success, main) !important;
}
.error-color {
    color: palette(error, main) !important;
}
.error-button {
    background-color: palette(error, dark) !important;
    color: palette(error, contrastText) !important;
}
.full-size {
    height: 100% !important;
    width: 100% !important;
}
.flex-start {
    justify-content: flex-start;
    .MuiButton-contained {
        margin-right: 0.5em;
    }
}
.flex-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.flex-end {
    display: flex !important;
    justify-content: flex-end;
}
.space-between {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}
.margin-auto {
    margin: auto;
}
.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.word-wrap {
    word-break: break-word;
}
.cursor-pointer {
    cursor: pointer;
}
.header-height {
    height: $header-height;
}
.margin-1 {
    margin: 1em !important;
}
.margin-2 {
    margin: 2em !important;
}
.text-align-left {
    text-align: left !important;
}
.text-align-center {
    text-align: center !important;
}
.text-align-right {
    text-align: right !important;
}
.left {
    justify-content: flex-start !important;
    text-align: left !important;
}
.center {
    justify-content: center !important;
    text-align: center !important;
}
.right {
    justify-content: flex-end !important;
    text-align: right !important;
}
.reduce-top-sm {
    margin-top: -20px;
}

.text-600 {
    font-weight: 600 !important;
}

.link {
    cursor: pointer;
    font-weight: 600 !important;
    &:hover {
        text-decoration: underline;
        background-color: palette(grey, 200);
        border-radius: shape(borderRadius);
    }
}
.full-width {
    width: 100% !important;
}