.quick-access-widget {
    border: 1px solid #ddd;
    border-radius: 0px !important;
    .card-header {
        padding: 0;

        .header {
            @extend .aligned-row;
            @include typography(body1);
            padding: 5px 10px 5px 10px;
            background-color: palette(primary, main);
            color: palette(primary, contrastText);
            text-transform: uppercase;
            font-weight: 600 !important;
        }

        .sub-header {
            @extend .aligned-row;
            color: palette(grey, A700);
            background-color: palette(grey, 200);
            min-width: 0;

            div {
                min-height: 0;
            }

            button {
                padding: 5px 10px 5px 10px;
                min-width: 0;
                min-height: 0;

                span {
                    @include typography(body2);
                }
            }
        }
    }

    .card-content {
        padding: 0 !important;

        .list-item {
            padding: 0;
            border-bottom: 1px solid #ddd;
        }
    }
}

.study-element {
    button {
        padding: 8px 14px 8px 14px;
        width: 100%;
        justify-content: flex-start;

        span div {
            display: flex;
            flex-direction: column;
            line-height: 1.2;

            span {
                text-align: start;
            }

            li:nth-child(2) {
                @include typography(caption);
                text-align: start;
                color: palette(grey, 800);
            }
        }
    }
}

.effort-status {
    text-align: center;
    padding: 0px 15px 10px;
    &.warning {
        font-weight: 600;
        color: palette(error, dark);
    }
}

.coming-soon {
    background-color: white;
    color: palette(grey, 700);
    border: solid 1.5px palette(grey, 300);
    padding: 30px 20px;
    div:first-child {
        @include typography(subtitle2);
    }
    div:nth-child(2) {
        @include typography(h5);
        text-transform: uppercase;
        font-weight: 600 !important;
    }
}
