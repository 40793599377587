.simulator-summary-card {
    border-left-style: solid;
    border-left-color: palette(primary, main);
    border-left-width: 8px;

    div {
        padding: 3px 6px 3px 6px;
    }
    span:nth-child(1) {
        @include typography(subtitle2);
        white-space: nowrap;
    }
    span:nth-child(3) {
        @include typography(h5);
    }
    span:nth-child(5) {
        @include typography(subtitle2);
        font-size: small !important;
        color: palette(text, hint);
        white-space: nowrap;
    }

    div div:nth-child(2) {
        margin: auto;
        text-align: center;
        svg {
            font-size: 3.1875rem;
        }
    }
}
.simulator-summary-card[data-currency="true"] {
    border-left-color: palette(success, main);
    span:nth-child(3) {
        color: palette(success, main);
    }
}
.simulator-summary-card[data-currency="true"][data-negative="true"] {
    border-left-color: palette(error, main);
}